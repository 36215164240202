<div class="cea-header-view">
  <div class="cea-offset-md-1 cea-col-md-10 cea-offset-sm-0 cea-col-sm-12 cea-col-xs-12" id="cea-start-header-1">
    <div class="header-container text-center">
      <h2 class="row">{{'START.HEADER.TITLE' | translate }}</h2>
      <div class="row text-center">
        <p>
          {{'START.HEADER.MESSAGE_LINE_1' | translate }}<br>
          {{'START.HEADER.MESSAGE_LINE_2' | translate }}
        </p>
      </div>
    </div>
  </div>
</div>

<div class="cea-page" id="cea-page-1" style="padding-top: 0px;">
  <div class="cea-main-view">
    <div class="start">
      <div class="row text-center">
        <button id="es" class="btn btn-link center-block cea-hidden-sm cea-ns-hidden-sm cea-visible-xs cea-visible-xss cea-visible-xxs cea-ns-visible-xxs"
                style="max-width: 120px;font-weight:normal" *ngIf="!(this.translationService.currentLanguage === lang.spanish)"
                (click)="useLanguage(lang.spanish)">
          en Español
        </button>
        <button id="en" class="btn btn-link center-block cea-hidden-sm cea-ns-hidden-sm cea-visible-xs cea-visible-xss cea-visible-xxs cea-ns-visible-xxs"
                style="max-width: 120px;font-weight:normal" *ngIf="!(this.translationService.currentLanguage === lang.english)"
                (click)="useLanguage(lang.english)">
          in English
        </button>
        <button id="zh" class="btn btn-link center-block cea-hidden-sm cea-ns-hidden-sm cea-visible-xs cea-visible-xss cea-visible-xxs cea-ns-visible-xxs chinese"
                style="max-width: 120px;font-weight:normal" *ngIf="!(this.translationService.currentLanguage === lang.chinese)"
                (click)="useLanguage(lang.chinese)">
          中文
        </button>
        <div class="page-col cea-offset-lg-3 cea-col-lg-7 cea-offset-md-3 cea-col-md-7 cea-offset-sm-2 cea-col-sm-9 cea-ns-col-sm-12">
          <button class="btn cea-policy-btn" required routerLink="/homeowner/general" id="homeowner-general-btn" [queryParams]="{ reset: 'true' }">
            <div class="button-image" [inlineSVG]="imageUrl('/files/assets/img/home.svg')"></div>
            {{'START.HOMEOWNER_BUTTON' | translate }}
          </button>
          <button class="btn cea-policy-btn" required routerLink="/renter/general" id="renter-general-btn" [queryParams]="{ reset: 'true' }">
            <div class="button-image" [inlineSVG]="imageUrl('/files/assets/img/renter.svg')"></div>
            {{'START.RENTER_BUTTON' | translate }}
          </button>
          <div class="btn btn-link cea-hidden-xs cea-hidden-xxs cea-hidden-xss cea-ns-hidden-xxs"
               style="vertical-align: top; width: 90px; display: inline-block; margin-top: 8px; padding-left: 0;">
            <button id="es" class="btn btn-link cea-hidden-xs cea-hidden-xxs cea-hidden-xss cea-ns-hidden-xxs"
                    style="float:left;font-weight:normal"
                    *ngIf="!(this.translationService.currentLanguage === lang.spanish)"
                    (click)="useLanguage(lang.spanish)">
              en Español
            </button>
            <button id="en" class="btn btn-link cea-hidden-xs cea-hidden-xxs cea-hidden-xss cea-ns-hidden-xxs"
                    style="float:left;font-weight:normal"
                    *ngIf="!(this.translationService.currentLanguage === lang.english)"
                    (click)="useLanguage(lang.english)">
              in English
            </button>
            <button id="zh" class="btn btn-link cea-hidden-xs cea-hidden-xxs cea-hidden-xss cea-ns-hidden-xxs chinese"
                    *ngIf="!(this.translationService.currentLanguage === lang.chinese)"
                    style="float:left;font-weight:normal" (click)="useLanguage(lang.chinese)">
              中文
            </button>
          </div>
        </div>
      </div>

      <div class="row text-center">
        <div class="page-col cea-offset-lg-3 cea-col-lg-7 cea-offset-md-3 cea-col-md-7 cea-offset-sm-2 cea-col-sm-9 cea-ns-col-sm-12">
          <button class="btn cea-policy-btn" required routerLink="/condo/general" id="condo-general-btn" [queryParams]="{ reset: 'true' }">
            <div class="button-image" [inlineSVG]="imageUrl('/files/assets/img/condo.svg')"></div>
            {{'START.CONDO_BUTTON' | translate }}
          </button>
          <button class="btn cea-policy-btn" required routerLink="/mobilehome/general" id="mobilehome-general-btn" [queryParams]="{ reset: 'true' }">
            <div class="button-image" [inlineSVG]="imageUrl('/files/assets/img/mobilehome.svg')"></div>
            {{'START.MOBILEHOME_BUTTON' | translate }}
          </button>
          <div class="btn cea-hidden-xs cea-hidden-xxs cea-hidden-xss cea-ns-hidden-xxs"
               style="vertical-align: top; width: 90px; display: inline-block; margin-top: 8px; padding-left: 0;">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="display: none">
    <div class="cea-visible-lg">
      LG
    </div>
    <div class="cea-visible-md">
      MD
    </div>
    <div class="cea-visible-sm">
      SM
    </div>
    <div class="cea-visible-xs">
      XS
    </div>
    <div class="cea-visible-xxs">
      XXS
    </div>
    {{screenWidth}}
  </div>
</div>

