import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HeaderForHeader, LabelForPdf, QuoteForPdf } from "../../models/ComparePdf.model";
import { moduleType } from "../../models/module.type";
import { Estimate } from "../../models/estimate.model";
import { EstimateBuilderService } from "../estimate-builder/estimate-builder.service";

@Injectable({
    providedIn: "root"
})
export class PrintBuilderService {
    constructor(private translate: TranslateService,
        private estimateBuilderService: EstimateBuilderService) {

    }

    public buildQuotePdf(estimate: Estimate, usePosition?: boolean): QuoteForPdf
    {
        if (estimate.PolicyType == moduleType.renter) {
            return this.buildRenter(estimate, usePosition);
        }

        if (estimate.PolicyType == moduleType.condo) {
            return this.buildCondo(estimate, usePosition);
        }

        if (estimate.PolicyType == moduleType.mobilehome) {
            return this.buildMobilehome(estimate, usePosition);
        }

        if (estimate.PolicyType == moduleType.homeowner) {
            return this.buildHomeowner(estimate, usePosition);
        }

        return null;
    }

    public buildHeader(estimate: Estimate): HeaderForHeader {
        const header = new HeaderForHeader();
        header.langCode = this.translate.currentLang;
        header.reportAddress = estimate.FullAddress;
        header.reportTitle = estimate.FullAddress;
        header.reportType = this.getReportType(estimate.PolicyType, estimate.PolicyDate, estimate.IsFrfMode);

        return header;
    }

    public buildLabel(estimate: Estimate) {
        let label = new LabelForPdf();

        if (estimate.PolicyType == moduleType.renter) {
            label.title = this.translate.instant("COMPARE_SEND.RENTERS_ESTIMATE").toUpperCase();
            label.minimum = this.translate.instant("COMPARE_SEND.MINIMUM_RENTER");
        }
        else if (estimate.PolicyType == moduleType.condo) {
            label.title = this.translate.instant("COMPARE_SEND.CONDO_OWNERS_ESTIMATES").toUpperCase();
            label.minimum = this.translate.instant("COMPARE_SEND.MINIMUM_CONDO");
        }
        else if (estimate.PolicyType == moduleType.mobilehome) {
            label.title = this.translate.instant("COMPARE_SEND.MOBILEHOME_OWNERS_ESTIMATES").toUpperCase();
            label.minimum = this.translate.instant("COMPARE_SEND.MINIMUM_MOBILE");
        }
        else {
            label.title = this.translate.instant("COMPARE_SEND.HOMEOWNERS_ESTIMATE").toUpperCase();
            label.minimum = this.translate.instant("COMPARE_SEND.MINIMUM_HOMEOWNER");
        }

        label.disclaimer = this.translate.instant("COMPARE_SEND.TERMS_TEXT")

        label.policyDetails = this.translate.instant("COMPARE_SEND.FIELDS.POLICY_DETAILS") + ":";
        label.policyType = this.translate.instant("COMPARE_SEND.FIELDS.POLICY_TYPE");
        label.estimateDate = this.translate.instant("COMPARE_SEND.FIELDS.ESTIMATE_DATE");
        label.estimateStart = this.translate.instant("COMPARE_SEND.FIELDS.EFFECTIVE_START");
        label.zipCode = this.translate.instant("COMPARE_SEND.FIELDS.ZIP");
        label.yearBuilt = this.translate.instant("COMPARE_SEND.FIELDS.YEAR_BUILT");
        label.foundationType = this.translate.instant("COMPARE_SEND.FIELDS.FOUNDATION_TYPE");
        label.roofType = this.translate.instant("COMPARE_SEND.FIELDS.ROOF_TYPE");
        label.frame = this.translate.instant("COMPARE_SEND.FIELDS.FRAME");
        label.stories = this.translate.instant("COMPARE_SEND.FIELDS.NUMBER_OF_STORIES");
        label.masonryCoverage = this.translate.instant("COMPARE_SEND.FIELDS.MANSONRY_VENEER_COVERAGE");
        label.bracingSystem = this.translate.instant("COMPARE_SEND.FIELDS.BRACING_SYSTEM");

        label.dwelling = (estimate.PolicyType === moduleType.condo ? this.translate.instant("COMPARE_SEND.FIELDS.BUILDING_PROPERTY") : this.translate.instant("COMPARE_SEND.FIELDS.DWELLING")) + ":";
        label.dwellingCoverage = this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE");
        label.dwellingDeductibleAmount = this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " ($)";
        label.dwellingDeductiblePercent = this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " (%)";
        
        label.personalProperty = this.translate.instant("COMPARE_SEND.FIELDS.PERSONAL_PROPERTY") + ":";
        label.personalPropertyCoverage = this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE");
        label.personalPropertyDeductibleAmount = this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " ($)";
        label.personalPropertyDeductiblePercent = this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " (%)";
        label.breakableCoverage = this.translate.instant("COMPARE_SEND.FIELDS.BREAKABLE_COVERAGE");

        label.lossAssessment = this.translate.instant("COMPARE_SEND.FIELDS.LOSS_ASSESSMENT") + ":";
        label.lossAssessmentCoverage = this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE");
        label.lossAssessmentDeductibleAmount = this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " ($)";
        label.lossAssessmentDeductiblePercent = this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " (%)";

        label.lossOfUse = this.translate.instant("COMPARE_SEND.FIELDS.LOSS_OF_USE") + ":";
        label.lossOfUseCoverage = this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE");

        label.buildingCode = this.translate.instant("COMPARE_SEND.FIELDS.BUILDING_CODE_UPGRADE") + ":";
        label.buildingCodeCoverage = this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE");

        label.hazardDiscount = this.translate.instant("COMPARE_SEND.FIELDS.STATUS"); 
        label.hazardStatus = this.translate.instant("COMPARE_SEND.FIELDS.HAZARD_REDUCTION") + ":";
        label.hazardHrdIndicator = this.translate.instant("COMPARE_SEND.FIELDS.DISCOUNT");

        label.premium = this.translate.instant("COMPARE_SEND.FIELDS.PREMIUM") + ":";
        label.premiumAnnual = this.translate.instant("COMPARE_SEND.FIELDS.ANNUAL");
        label.premiumMonthly = this.translate.instant("COMPARE_SEND.FIELDS.MONTHLY");

        return label;
    }

    private getReportType(policy: number, policyDate: Date, isFrfMode: boolean): string {
        let reportType = "";
        if (policy === moduleType.renter) {
            reportType = isFrfMode ? "RenterFrf" : "Renter";
        }

        if (policy === moduleType.condo) {
            reportType = isFrfMode ? "CondoFrf": "Condo";
        }

        if (policy === moduleType.homeowner) {
            reportType = isFrfMode ? "HomeFrf": "Home2019" ;
        }

        if (policy === moduleType.mobilehome) {
            reportType = isFrfMode ? "MobilehomeFrf" : "Mobilehome2019";
        }

        return reportType;
    }

    private buildBase(estimate: Estimate, usePosition?: boolean): QuoteForPdf {
        const quotePdf = new QuoteForPdf();

        quotePdf.estimateDate = this.estimateBuilderService.formatDate(new Date());
        quotePdf.estimateStart = estimate.PolicyEffectiveDate;

        quotePdf.yearBuilt = estimate.YearBuilt ? estimate.YearBuilt.toString() : "0";
        quotePdf.zipCode = estimate.ZipCode;

        quotePdf.policyType = estimate.PolicyName;
        if (usePosition === true && Number.isInteger(estimate.Position)) {
            quotePdf.policyType = quotePdf.policyType + " #" + estimate.Position;
        }
        quotePdf.premiumAnnual = estimate.AnnualPremiumAmount;
        quotePdf.premiumMonthly = estimate.MonthlyPremiumAmount;

        quotePdf.personalPropertyCoverage = estimate.PersonalPropertyCoverageAmount;

        quotePdf.personalPropertyDeductibleAmount = estimate.PersonalPropertyDeductibleAmount;
        quotePdf.personalPropertyDeductiblePercent = estimate.PersonalPropertyDeductiblePercent;
        

        quotePdf.lossOfUseCoverage = estimate.LossOfUseCoverageAmount;
        quotePdf.breakableCoverage = estimate.BreakablesCoverageReadable;

        return quotePdf;
    }

    private buildHomeowner(estimate: Estimate, usePosition?: boolean): QuoteForPdf {
        const quotePdf = this.buildBase(estimate, usePosition);

        quotePdf.buildingCodeCoverage = estimate.BuildingCodeUpgradeLimitIncreaseAmount;
        quotePdf.dwellingCoverage = estimate.InsuredValueOfHomeAmount;
        quotePdf.dwellingDeductibleAmount = estimate.DwellingDeductibleAmount;
        quotePdf.dwellingDeductiblePercent = estimate.DwellingDeductiblePercent;

        quotePdf.foundationType = estimate.FoundationTypeName;
        quotePdf.frame = estimate.ConstructionReadable;
        quotePdf.masonryCoverage = estimate.MasonryVeneerCoverageReadable;
        quotePdf.roofType = estimate.RoofTypeName;
        quotePdf.stories = estimate.NumberOfStoriesReadable;

        quotePdf.hazardStatus = estimate.HrdStatusReadable;
        quotePdf.hazardDiscount = estimate.HrdDiscountReadable;

        return quotePdf;
    }

    private buildMobilehome(estimate: Estimate,  usePosition?: boolean): QuoteForPdf {
        const quotePdf = this.buildBase(estimate, usePosition);

        quotePdf.bracingSystem = estimate.HasEarthquakeResistantBracingSystemReadable;

        quotePdf.buildingCodeCoverage = estimate.BuildingCodeUpgradeLimitIncreaseAmount;
        quotePdf.dwellingCoverage = estimate.InsuredValueOfHomeAmount;
        quotePdf.dwellingDeductibleAmount = estimate.DwellingDeductibleAmount;
        quotePdf.dwellingDeductiblePercent = estimate.DwellingDeductiblePercent;

        quotePdf.hazardStatus = estimate.HrdStatusReadable;
        quotePdf.hazardDiscount = estimate.HrdDiscountReadable;
        quotePdf.hazardHrdIndicator = estimate.HrdQualifiedReadable;

        return quotePdf;
    }

    private buildRenter(estimate: Estimate, usePosition?: boolean): QuoteForPdf {
        const quotePdf = this.buildBase(estimate, usePosition);

        quotePdf.lossAssessmentCoverage = estimate.LossAssessmentCoverageAmount;
        quotePdf.lossAssessmentDeductibleAmount = estimate.LossAssessmentDeductibleAmount;
        quotePdf.lossAssessmentDeductiblePercent = estimate.LossAssessmentDeductiblePercent;

        return quotePdf;
    }

    private buildCondo(estimate: Estimate, usePosition?: boolean): QuoteForPdf {
        const quotePdf = this.buildBase(estimate, usePosition);

        quotePdf.dwellingCoverage = estimate.BuildingPropertyCoverageAmount;
        quotePdf.dwellingDeductibleAmount = estimate.BuildingPropertyDeductibleAmount;
        quotePdf.dwellingDeductiblePercent = estimate.BuildingPropertyDeductiblePercent;

        quotePdf.lossAssessmentCoverage = estimate.LossAssessmentCoverageAmount;
        quotePdf.lossAssessmentDeductibleAmount = estimate.LossAssessmentDeductibleAmount;
        quotePdf.lossAssessmentDeductiblePercent = estimate.LossAssessmentDeductiblePercent;

        return quotePdf;
    }
}
