
<div class="cea-header-view">
    <!-- detailedheader.html -->
    <div class="cea-page-header" id="cea-detailed-header-1">
        <div class="container">
            <div class="row header-container" style="background-color: #0B3C5D;">
                <div class="cea-col-sm-12 cea-col-xs-12 header-area">
                    <div class="row">
                        <div class="detailed-header text-center cea-col-md-5 cea-col-sm-5 cea-col-xs-5 cea-col-xxs-12 cea-col-xss-12">
                            <div class="header-image" *ngIf="policytype === modules.homeowner"
                                [inlineSVG]="imageUrl('/files/assets/img/home.svg')"></div>
                            <div class="header-image" *ngIf="policytype === modules.renter"
                                [inlineSVG]="imageUrl('/files/assets/img/renter.svg')"></div>
                            <div class="header-image" *ngIf="policytype === modules.condo"
                                [inlineSVG]="imageUrl('/files/assets/img/condo.svg')"></div>
                            <div class="header-image" *ngIf="policytype === modules.mobilehome"
                                [inlineSVG]="imageUrl('/files/assets/img/mobilehome.svg')"></div>
                            <div class="row title" [ngClass]="{'title-font': this.translationService.currentLanguage!=='es','title-font-es':this.translationService.currentLanguage=='es'}">
                                <span *ngIf="policytype === modules.homeowner && !calcInput.PersonalPropertyDeductible && calcInput.PersonalPropertyCoverage">
                                    {{"GLOBAL.Homeowners" | translate}}

                                    <app-cea-help-button *ngIf="policytype === modules.homeowner"
                                                         [stype]="'header'"
                                                         [title]="'HOMEOWNERS.CALCULATIONS.HELP.DIFFERENCE.TITLE'"
                                                         [text1]="'HOMEOWNERS.CALCULATIONS.HELP.DIFFERENCE.PARAGRAPH1'"
                                                         [text2]="'HOMEOWNERS.CALCULATIONS.HELP.DIFFERENCE.PARAGRAPH2'">
                                    </app-cea-help-button>

                                </span>
                                <span *ngIf="policytype === modules.homeowner && (!calcInput.PersonalPropertyCoverage || (calcInput.PersonalPropertyDeductible !== 0 && calcInput.PersonalPropertyDeductible !== null))">
                                    {{"GLOBAL.Homeowners Choice" | translate}}

                                    <app-cea-help-button *ngIf="policytype === modules.homeowner"
                                                         [stype]="'header'"
                                                         [title]="'HOMEOWNERS.CALCULATIONS.HELP.DIFFERENCE.TITLE'"
                                                         [text1]="'HOMEOWNERS.CALCULATIONS.HELP.DIFFERENCE.PARAGRAPH1'"
                                                         [text2]="'HOMEOWNERS.CALCULATIONS.HELP.DIFFERENCE.PARAGRAPH2'">
                                    </app-cea-help-button>

                                </span>

                                <span *ngIf="policytype === modules.renter">{{"GLOBAL.Renters" | translate}}</span>
                                <span style="white-space: nowrap;"
                                    *ngIf="policytype === modules.condo">{{"GLOBAL.CONDO_OWNERS" | translate}}</span>
                                <span *ngIf="policytype === modules.mobilehome && !calcInput.PersonalPropertyDeductible && calcInput.PersonalPropertyCoverage">
                                    {{"GLOBAL.Mobilehome" | translate}}

                                    <app-cea-help-button *ngIf="policytype === modules.mobilehome"
                                                         [stype]="'header'"
                                                         [title]="'HOMEOWNERS.CALCULATIONS.HELP.DIFFERENCE.TITLE'"
                                                         [text1]="'HOMEOWNERS.CALCULATIONS.HELP.DIFFERENCE.PARAGRAPH1'"
                                                         [text2]="'HOMEOWNERS.CALCULATIONS.HELP.DIFFERENCE.PARAGRAPH2'">
                                    </app-cea-help-button>

                                </span>

                                <span *ngIf="policytype === modules.mobilehome  && (!calcInput.PersonalPropertyCoverage || (calcInput.PersonalPropertyDeductible !== 0 && calcInput.PersonalPropertyDeductible !== null))">
                                    {{"GLOBAL.Mobilehome Choice" | translate}}

                                    <app-cea-help-button *ngIf="policytype === modules.mobilehome"
                                                         [stype]="'header'"
                                                         [title]="'HOMEOWNERS.CALCULATIONS.HELP.DIFFERENCE.TITLE'"
                                                         [text1]="'HOMEOWNERS.CALCULATIONS.HELP.DIFFERENCE.PARAGRAPH1'"
                                                         [text2]="'HOMEOWNERS.CALCULATIONS.HELP.DIFFERENCE.PARAGRAPH2'">
                                    </app-cea-help-button>

                                </span>

                                <span style="white-space:nowrap">


                                </span>
                            </div>
                            <div class="row details">{{"HOMEOWNERS.CALCULATIONS.ZIPCODE" | translate}}:
                                <span>{{quoterRequest.ZipCode}}</span></div>
                            <div class="row details">{{"HOMEOWNERS.CALCULATIONS.EFFECTIVE_POLICY_START" | translate}}
                                : <span>{{quoterRequest.StartYear}}</span></div>
                        </div>
                        <div
                            class="cea-col-md-7 cea-col-sm-7 cea-col-xs-7 cea-col-xxs-12 cea-col-xss-12 guage-header">
                            <div class="gauges-container">
                                <ngx-gauge *ngIf="processing == true" class="fakeGuage" type="semi" value="-" size="240"
                                    [max]="100" [thick]="36" backgroundColor="rgb(221, 226, 231)"
                                    foregroundColor="rgb(153, 153, 153)">
                                </ngx-gauge>
                                <ngx-gauge *ngIf="processing == false" class="actualGuage" type="semi" size="240"
                                  [value]="gaugeValue" [max]="'400'" [thick]="36" prepend="$"
                                    backgroundColor="rgb(221, 226, 231)" foregroundColor="rgb(153, 153, 153)">
                                </ngx-gauge>
                            </div>
                            <div class="gauge-title" *ngIf="processing == false">
                                {{ "HOMEOWNERS.CALCULATIONS.ESTIMATED_MONTHLY_PREMIUM" | translate}}</div>
                            <div class="gauge-subtitle" *ngIf="processing == false && policytype !== modules.homeowner">
                                {{ "HOMEOWNERS.CALCULATIONS.ANNUAL_PREMIUM" | translate}}:
                                ${{quoterResponse?.AnnualPremium | number : '1.2-2'}}</div>

                            <div class="gauge-subtitle"
                                *ngIf="processing == false && policytype === modules.homeowner && quoterResponse !== undefined">
                                {{ "HOMEOWNERS.CALCULATIONS.ANNUAL_PREMIUM" | translate}}:
                                ${{quoterResponse.AnnualPremium | number : '1.2-2'}}</div>
                            <div *ngIf="processing == true" class="gauge-title">
                                {{ "HOMEOWNERS.CALCULATIONS.ESTIMATE_PENDING" | translate}}...
                            </div>
                        </div>
                    </div>
                    <span id="ready" *ngIf="processing==false"></span>
                </div>
            </div>
        </div>
    </div>


    <div class="offset-md-0 cea-col-md-12 offset-sm-0 cea-col-sm-12 cea-col-xs-12" id="cea-language-header-1"
        *ngIf="policytype === modules.condo">
        <div class="header-language">
            <button id="es" class="btn btn-link btn-language" style="font-weight:normal" *ngIf="!(this.translationService.currentLanguage === lang.spanish)"
                (click)="useLanguage(lang.spanish)">
                en Español
            </button>
            <button id="en" class="btn btn-link btn-language" style="font-weight:normal" *ngIf="!(this.translationService.currentLanguage === lang.english)"
                (click)="useLanguage(lang.english)">
                in English
            </button>
            <button id="zh" class="btn btn-link chinese btn-language" style="font-weight:normal"
                *ngIf="!(this.translationService.currentLanguage === lang.chinese)" (click)="useLanguage(lang.chinese)">
                中文
            </button>
        </div>
    </div>

    <div class="offset-md-0 cea-col-md-12 offset-sm-0 cea-col-sm-12 cea-col-xs-12" id="cea-language-header-1"
        *ngIf="policytype === modules.renter">
        <div class="header-language">
            <button id="es" class="btn btn-link" style="font-weight:normal" *ngIf="!(this.translationService.currentLanguage === lang.spanish)"
                (click)="useLanguage(lang.spanish)">
                en Español
            </button>
            <button id="en" class="btn btn-link" style="font-weight:normal" *ngIf="!(this.translationService.currentLanguage === lang.english)"
                (click)="useLanguage(lang.english)">
                in English
            </button>
            <button id="zh" class="btn btn-link chinese" style="font-weight:normal"
                *ngIf="!(this.translationService.currentLanguage === lang.chinese)" (click)="useLanguage(lang.chinese)">
                中文
            </button>
        </div>
    </div>

    <div class="offset-md-0 cea-col-md-12 offset-sm-0 cea-col-sm-12 cea-col-xs-12" id="cea-language-header-1"
        *ngIf="policytype === modules.homeowner">
        <div class="header-language">
            <button id="es" class="btn btn-link" style="font-weight:normal" *ngIf="!(this.translationService.currentLanguage === lang.spanish)"
                (click)="useLanguage(lang.spanish)">
                en Español
            </button>
            <button id="en" class="btn btn-link" style="font-weight:normal" *ngIf="!(this.translationService.currentLanguage === lang.english)"
                (click)="useLanguage(lang.english)">
                in English
            </button>
            <button id="zh" class="btn btn-link chinese" style="font-weight:normal"
                *ngIf="!(this.translationService.currentLanguage === lang.chinese)" (click)="useLanguage(lang.chinese)">
                中文
            </button>
        </div>
    </div>

    <div class="offset-md-0 cea-col-md-12 offset-sm-0 cea-col-sm-12 cea-col-xs-12" id="cea-language-header-1"
        *ngIf="policytype === modules.mobilehome">
        <div class="header-language">
            <button id="es" class="btn btn-link" style="font-weight:normal" *ngIf="!(this.translationService.currentLanguage === lang.spanish)"
                (click)="useLanguage(lang.spanish)">
                en Español
            </button>
            <button id="en" class="btn btn-link" style="font-weight:normal" *ngIf="!(this.translationService.currentLanguage === lang.english)"
                (click)="useLanguage(lang.english)">
                in English
            </button>
            <button id="zh" class="btn btn-link chinese" style="font-weight:normal"
                *ngIf="!(this.translationService.currentLanguage === lang.chinese)" (click)="useLanguage(lang.chinese)">
                中文
            </button>
        </div>
    </div>
</div>


<div class="cea-page" id="cea-page-1">

  <div class="cea-main-view ng-scope" ui-view="">
    <form name="theForm" novalidate="" class="ng-pristine ng-valid ng-scope">
      <div class="row cea-form">
        <div class="cea-col-sm-12 cea-col-md-12 offset-md-0 offset-sm-0">
          <div id="form-container">
            <div id="form-views" ui-view="" class="ng-scope">

                <div class="cea-alternate cea-interactive slider-view"
                     *ngIf="policytype === modules.homeowner">

                    <div class="retrofit" *ngIf="showRetrofitNotice">
                        {{'HOMEOWNERS.CALCULATIONS.RETROFIT_GRANT' | translate }}
                        <a href="{{eqaUrl | lowercase}}{{'GLOBAL.BRACEBOLT_GRANT_URL' | translate | lowercase}}" target="_parent"
                           (click)="trackBraceBoltGrantsClick(eqaUrl + Grantsurl)">{{'HOMEOWNERS.CALCULATIONS.RETROFIT_LINK' | translate}}</a>
                    </div>

                    <div app-cea-slider [sliderSettings]="DwellingDeductibleVariableSlider"
                         class="form-group" (moveTop)="moveTop($event)"
                         (setSliderValue)="setSliderValue($event,'BuildingPropertyDeductible')"></div>


                    <div app-cea-slider [sliderSettings]="BuildingPropertyCoverageVariableSlider"
                         class="form-group" (moveTop)="moveTop($event)" (setSliderValue)="setSliderValue($event)"></div>

                    <div app-cea-radio-group (setRadioValue)="setSliderValue($event)" (moveTop)="moveTop($event)"
                         [radioGroupSettings]="BuildingCodeUpgradeIncreasedLimitRadioGroup"
                         *ngIf="!frfMode" class="form-group"></div>

                    <div app-cea-slider [sliderSettings]="PersonalPropertyCoverageVariableSlider"
                         class="form-group" (moveTop)="moveTop($event)"
                         (setSliderValue)="setSliderValue($event,'PersonalPropertyCoverage')"></div>

                    <div app-cea-slider [sliderSettings]="PersonalPropertyDeductibleVariableSlider"
                         class="form-group" (moveTop)="moveTop($event)"
                         (setSliderValue)="setSliderValue($event,'PersonalPropertyDeductible')"
                         *ngIf="!showPersonalPropertyDeductibleVariableSliderFake"></div>

                    <div app-cea-slider [sliderSettings]="PersonalPropertyDeductibleVariableSlider"
                         class="form-group" (moveTop)="moveTop($event)"
                         (setSliderValue)="setSliderValue($event,'PersonalPropertyDeductible')"
                         *ngIf="showPersonalPropertyDeductibleVariableSliderFake"></div>



                    <div app-cea-radio-group (setRadioValue)="setSliderValue($event)"
                         (moveTop)="moveTop($event)" [radioGroupSettings]="WantsBreakableCoverageRadioGroup"
                         *ngIf="!showWantsBreakableCoverageRadioGroupFake && !frfMode" class="form-group"></div>
                    <div app-cea-radio-group (setRadioValue)="setSliderValue($event)"
                         [radioGroupSettings]="WantsBreakableCoverageRadioGroupFake"
                         (moveTop)="moveTop($event)" *ngIf="showWantsBreakableCoverageRadioGroupFake && !frfMode"
                         class="form-group"></div>

                    <div app-cea-slider [sliderSettings]="LossOfUseCoverageVariableSlider"
                         (moveTop)="moveTop($event)" class="form-group"
                         (setSliderValue)="setSliderValue($event)"
                         *ngIf="!showLossOfUseCoverageVariableSlider1"></div>
                    <div app-cea-slider [sliderSettings]="LossOfUseCoverageVariableSlider1"
                         (moveTop)="moveTop($event)" class="form-group"
                         (setSliderValue)="setSliderValue($event)"
                         *ngIf="showLossOfUseCoverageVariableSlider1"></div>

                </div>


              <div class="cea-alternate cea-interactive slider-view" *ngIf="policytype === modules.condo">
                <div app-cea-slider [sliderSettings]="BuildingPropertyCoverageVariableSlider"
                     class="form-group" (moveTop)="moveTop($event)"
                     (setSliderValue)="setSliderValue($event,'BuildingPropertyCoverage')"></div>

                <div app-cea-slider [sliderSettings]="BuildingPropertyDeductibleVariableSlider"
                     class="form-group" (moveTop)="moveTop($event)"
                     (setSliderValue)="setSliderValue($event,'BuildingPropertyDeductible')"
                     *ngIf="showBuildingPropertyDeductibleVariableSliderFake === false"></div>

                <div app-cea-slider [sliderSettings]="BuildingPropertyDeductibleVariableSliderFake"
                     (moveTop)="moveTop($event)" class="form-group"
                     (setSliderValue)="setSliderValue($event,'BuildingPropertyDeductible')"
                     *ngIf="showBuildingPropertyDeductibleVariableSliderFake === true"></div>

                <div app-cea-slider [sliderSettings]="PersonalPropertyCoverageVariableSlider"
                     (moveTop)="moveTop($event)" class="form-group"
                     (setSliderValue)="setSliderValue($event,'PersonalProperty')"
                     *ngIf="refreshPersonalPropertyCoverageVariableSlider === true"></div>

                <div app-cea-slider [sliderSettings]="PersonalPropertyCoverageVariableSlider"
                     (moveTop)="moveTop($event)" class="form-group"
                     (setSliderValue)="setSliderValue($event,'PersonalProperty')"
                     *ngIf="refreshPersonalPropertyCoverageVariableSlider === false"></div>

                <div app-cea-slider [sliderSettings]="PersonalPropertyDeductibleVariableSlider"
                     (moveTop)="moveTop($event)" class="form-group"
                     (setSliderValue)="setSliderValue($event,'PersonalPropertyDeductible')"
                     *ngIf="showPersonalPropertyDeductibleVariableSliderFake === false"></div>

                <div app-cea-slider [sliderSettings]="PersonalPropertyDeductibleVariableSliderFake"
                     (moveTop)="moveTop($event)" class="form-group"
                     (setSliderValue)="setSliderValue($event,'PersonalPropertyDeductible')"
                     *ngIf="showPersonalPropertyDeductibleVariableSliderFake === true"></div>

                <div app-cea-radio-group
                     (setRadioValue)="setSliderValue($event,'WantsBreakableCoverage')"
                     (moveTop)="moveTop($event)" [radioGroupSettings]="WantsBreakableCoverageRadioGroup"
                     *ngIf="showWantsBreakableCoverageRadioGroupFake === false && !frfMode" class="form-group"></div>

                <div app-cea-radio-group
                     (setRadioValue)="setSliderValue($event,'WantsBreakableCoverage')"
                     [radioGroupSettings]="WantsBreakableCoverageRadioGroupFake"
                     (moveTop)="moveTop($event)"
                     *ngIf="showWantsBreakableCoverageRadioGroupFake === true && !frfMode" class="form-group"></div>

                <div app-cea-slider [sliderSettings]="LossOfUseCoverageVariableSlider"
                     (moveTop)="moveTop($event)" class="form-group"
                     (setSliderValue)="setSliderValue($event,'LossOfUseCoverage')"
                     *ngIf="refreshLossOfUseCoverageVariableSlider === true"></div>

                <div app-cea-slider [sliderSettings]="LossOfUseCoverageVariableSlider"
                     (moveTop)="moveTop($event)" class="form-group"
                     (setSliderValue)="setSliderValue($event,'LossOfUseCoverage')"
                     *ngIf="refreshLossOfUseCoverageVariableSlider === false"></div>

                <div app-cea-slider [sliderSettings]="LossAssessmentCoverageVariableSlider"
                     class="form-group" (moveTop)="moveTop($event)"
                     (setSliderValue)="setSliderValue($event,'LossAssessmentCoverage')"></div>

                <div app-cea-slider [sliderSettings]="LossAssessmentDeductibleVariableSlider"
                     (moveTop)="moveTop($event)" class="form-group"
                     *ngIf="showLossAssessmentDeductibleVariableSliderFake === false"
                     (setSliderValue)="setSliderValue($event,'LossAssessmentDeductible')"></div>

                <div app-cea-slider [sliderSettings]="LossAssessmentDeductibleVariableSliderFake"
                     (moveTop)="moveTop($event)" class="form-group"
                     *ngIf="showLossAssessmentDeductibleVariableSliderFake === true"
                     (setSliderValue)="setSliderValue($event)"></div>
              </div>

              <div class="cea-alternate cea-interactive slider-view"
                   *ngIf="policytype === modules.renter">
                <div app-cea-slider [sliderSettings]="PersonalPropertyCoverageVariableSlider"
                     (moveTop)="moveTop($event)" class="form-group"
                     (setSliderValue)="setSliderValue($event)"></div>

                <div app-cea-slider [sliderSettings]="PersonalPropertyDeductibleVariableSlider"
                     (moveTop)="moveTop($event)" class="form-group"
                     (setSliderValue)="setSliderValue($event)"></div>

                <div app-cea-radio-group (setRadioValue)="setSliderValue($event)"
                     (moveTop)="moveTop($event)" [radioGroupSettings]="WantsBreakableCoverageRadioGroup"
                     class="form-group"
                     *ngIf="!frfMode"></div>

                <div app-cea-slider [sliderSettings]="LossOfUseCoverageVariableSlider"
                     (moveTop)="moveTop($event)" class="form-group"
                     (setSliderValue)="setSliderValue($event)"></div>
              </div>

              <div class="cea-alternate cea-interactive slider-view"
                   *ngIf="policytype === modules.mobilehome">

                <div app-cea-slider [sliderSettings]="DwellingDeductibleVariableSlider"
                     class="form-group" (moveTop)="moveTop($event)"
                     (setSliderValue)="setSliderValue($event,'BuildingPropertyDeductible')"></div>

                <div app-cea-slider [sliderSettings]="BCUCoverageVariableSlider" class="form-group"
                     (setSliderValue)="setSliderValue($event)" (moveTop)="moveTop($event)"></div>

                <div app-cea-slider [sliderSettings]="PersonalPropertyCoverageVariableSlider"
                     class="form-group" (moveTop)="moveTop($event)"
                     (setSliderValue)="setSliderValue($event,'PersonalPropertyCoverage')"></div>


                <div app-cea-slider [sliderSettings]="PersonalPropertyDeductibleVariableSlider"
                     class="form-group" (moveTop)="moveTop($event)" (moveTop)="moveTop($event)"
                     (setSliderValue)="setSliderValue($event,'PersonalPropertyDeductible')"
                     *ngIf="!showPersonalPropertyDeductibleVariableSliderFake"></div>

                <div app-cea-slider [sliderSettings]="PersonalPropertyDeductibleVariableSlider"
                     class="form-group" (moveTop)="moveTop($event)"
                     (setSliderValue)="setSliderValue($event,'PersonalPropertyDeductible')"
                     *ngIf="showPersonalPropertyDeductibleVariableSliderFake"></div>



                <div app-cea-radio-group (setRadioValue)="setSliderValue($event)"
                     [radioGroupSettings]="WantsBreakableCoverageRadioGroup" (moveTop)="moveTop($event)"
                     *ngIf="!showWantsBreakableCoverageRadioGroupFake && !frfMode" class="form-group"></div>
                <div app-cea-radio-group (setRadioValue)="setSliderValue($event)"
                     [radioGroupSettings]="WantsBreakableCoverageRadioGroupFake"
                     (moveTop)="moveTop($event)" *ngIf="showWantsBreakableCoverageRadioGroupFake && !frfMode"
                     class="form-group"></div>

                <div app-cea-slider [sliderSettings]="LossOfUseCoverageVariableSlider"
                     class="form-group" (setSliderValue)="setSliderValue($event)"
                     (moveTop)="moveTop($event)" *ngIf="!showLossOfUseCoverageVariableSlider1"></div>
                <div app-cea-slider [sliderSettings]="LossOfUseCoverageVariableSlider1"
                     class="form-group" (setSliderValue)="setSliderValue($event)"
                     (moveTop)="moveTop($event)" *ngIf="showLossOfUseCoverageVariableSlider1"></div>
              </div>


              <div class="row cea-top-margin20 cea-hidden-xxs cea-hidden-xss" style="width:100%;margin-left:0">
                <div class="back-column">
                  <button class="btn btn-primary btn-block" (click)="goBack()" id="calculations-back-btn-sm">
                    <em class="fas fa-arrow-left"></em>
                    {{'GLOBAL.BACK_BUTTON' | translate }}
                  </button>
                </div>
                <div class="cancel-column">
                  <button class="btn btn-accent btn-block" (click)="startOver()" id="calculations-start-over-btn-sm">
                    {{'COMPARE_SEND.CANCEL_BUTTON' | translate }}
                  </button>
                </div>
                <div class="download-column">
                  <button class="btn btn-primary btn-block" (click)="download($event)" id="calculations-download-btn-sm"
                          [disabled]="processing || showError">
                    {{'COMPARE_SEND.PRINT_DOWNLOAD_BUTTON' | translate }}
                  </button>
                </div>
                <div class="addto-column">
                  <button class="btn btn-primary btn-block" (click)="compare($event)" id="calculations-compare-button-sm"
                          [disabled]="processing || showError">
                    {{'COMPARE_SEND.COMPARE_BUTTON' | translate }}
                  </button>
                </div>
              </div>
              <div class="row cea-top-margin20 cea-hidden-sm cea-hidden-md cea-hidden-lg cea-hidden-xs cea-visible-xxs cea-visible-xss" style="width:100%;margin-left:0">
                <div class="addto-column">
                  <button class="btn btn-primary btn-block" (click)="compare($event)" id="calculations-compare-btn"
                          [disabled]="processing || showError">
                    {{'COMPARE_SEND.COMPARE_BUTTON' | translate }}
                  </button>
                </div>
                <div class="download-column">
                  <button class="btn btn-primary btn-block" (click)="download($event)" id="calculations-download-btn"
                          [disabled]="processing || showError">
                    {{'COMPARE_SEND.PRINT_DOWNLOAD_BUTTON' | translate }}
                  </button>
                </div>
                <div class="cancel-column">
                  <button class="btn btn-accent btn-block" (click)="startOver()" id="calculations-start-over-btn">
                    {{'COMPARE_SEND.CANCEL_BUTTON' | translate }}
                  </button>
                </div>
                <div class="back-column">
                  <button class="btn btn-primary btn-block" (click)="goBack()" id="calculations-go-back-btn">
                    <em class="fas fa-arrow-left"></em>
                    {{'GLOBAL.BACK_BUTTON' | translate }}
                  </button>
                </div>
                  <span id="requestCount" style="display: none">{{requestCount}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div  style="display: none">
      <div class="cea-visible-lg">
        LG
      </div>
      <div class="cea-visible-md">
        MD
      </div>
      <div class="cea-visible-sm">
        SM
      </div>
      <div class="cea-visible-xs">
        XS
      </div>
      <div class="cea-visible-xss cea-visible-xxs">
        XXS
      </div>
      {{screenWidth}}
    </div>
  </div>
</div>
