import { Component, NgZone, OnInit, HostListener } from "@angular/core";
import { Location } from '@angular/common';
import { environment } from "../../../environments/environment";
import { language } from "../../shared/models/language.model";
import { TranslationService } from "../../shared/services/translation/translation.service";
import { TranslateService } from "@ngx-translate/core";
import { HelperService } from "../../shared/services/helper/helper.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, Router } from "@angular/router";
import { QuoterRequest2Service } from "../../shared/services/quote-request/quote-request2.service";
import { QuoterRequest } from "../../shared/models/quoterRequest.model";
import { CalcInput } from "../../shared/models/CalcInput.model";
import { QuoterResponse } from "../../shared/models/QuoterResponse.model";
import { moduleType } from "../../shared/models/module.type";
import { CompareProperties } from "../../shared/models/compareTypes.model";
import moment from "moment";
import { PopupComponent } from "../../components";
import { ComparePdfModel, HeaderForHeader, LabelForPdf } from "../../shared/models/ComparePdf.model";
import { EmailPopupComponent } from "../../components/email-popup/email-popup.component";
import { PolicyDiffComponent } from '../../components/policy-diff/policy-diff.component';
import { ImageService } from "../../shared/services/images/image.service";
import { AnalyticsTrackingService } from 'src/app/shared/services/tracking/tracking.service';
import { PiValidatorsService } from 'src/app/shared/services/validators/pi-validators.service';
import { SessionService } from "../../shared/services/session/session.service";
import { QuoteStorageService } from "../../shared/services/quote-storage/quote-storage.service";
import { ErrorMessageService } from "../../shared/services/error-message/error-message.service";
import { PrintoutService } from "../../shared/services/printout/printout.service";
import { PrintBuilderService } from "../../shared/services/print-builder/print-builder.service";
import { EstimateBuilderService } from "../../shared/services/estimate-builder/estimate-builder.service";
import { DownloadFilePostModel } from "../../shared/models/Download.model";
import { CalcDatesService } from "../../shared/services/calc-dates/calc-dates.service";


@Component({
    selector: "app-compare",
    templateUrl: "./compare.component.html",
    styleUrls: ["./compare.component.scss"],
})
export class CompareComponent implements OnInit {

    constructor(
        public translationService: TranslationService,
        private translate: TranslateService,
        private helper: HelperService,
        private modalService: NgbModal,
        private router: Router,
        public zone: NgZone,
        private quoterRequestService: QuoterRequest2Service,
        private imageService: ImageService,
        private route: ActivatedRoute,
        private piValidatorsService: PiValidatorsService,
        private trackingService: AnalyticsTrackingService,
        private sessionService: SessionService,
        private quoteStorageService: QuoteStorageService,
        private errorMessageService: ErrorMessageService,
        private printoutService: PrintoutService,
        private printBuilderService: PrintBuilderService,
        private estimateBuilderService: EstimateBuilderService,
        private calcDatesService: CalcDatesService,
        private location: Location) {

        this.sessionService.listenUnload("/compare", function () {
            this.quoteStorageService.SaveState(this.sessionService.getRelativeUrl())
                .SaveComparePage(this.page)
                .SaveCompareItem(this.ids)
                .SaveLanguage(this.translationService.currentLanguage);
        }.bind(this));
    }

    lang = language;

    quote: QuoterRequest;
    firstSelectedPolicy = null;
    firstSelectedPolicyDate = null;
    quotes: QuoterResponse[] = [];
    calcInput: CalcInput = new CalcInput();
    compareCollection: CompareProperties[] = [];
    pCompareCollection: CompareProperties[] = [];

    showTable = false;
    _reload = true;

    ids: number[] = [];
    viewableIds: number[] = [];
    participatingInsurersUrl = environment.participatinginsurersurl;
    eqaUrl = environment.eqasiteurl;
    browserSupportUrl = environment.browsersupporturl;
    modules = moduleType;
    selectedQuotes = [];
    table = [];
    page = 1;
    pages = 1;
    perPage = 4;
    renderLandscape = false;
    policy = null;
    deductible = 0;
    quotesList = [];
    monthly = null;
    annually = null;
    findAgentUrl = "";
    screenWidth = 0;
    supportInsurerTxt = "";
    comparepdf: ComparePdfModel = null;

    ngOnInit() {
        setTimeout(() => {
            this.init(false);
        }, 10);
        this.checkComparison();
        this.backToTop();
    }

    checkComparison() {
        this.quoteStorageService.RemoveCompareItem();
        this.quoterRequestService.setAddNewCalc(false);
    }

    init(languageChanged) {
        let that = this;
        setTimeout(() => {
            that.reload();

            setTimeout(() => {
                if (languageChanged === true) {
                    this.quotes = [];
                }
                this.quote = this.quoterRequestService.getSavedQuoterRequest();
                this.quotesList = this.quoterRequestService.getSavedQuotes();
                if (this.quotesList && this.quotesList.length > 0) {
                    this.findAgentUrl = this.piValidatorsService.GetPiByName(this.quotesList[0].quote.ParticipatingInsurer.trim(), this.eqaUrl.toLocaleLowerCase() + this.translate.instant("GLOBAL.PARTICIPATING_INSURER_URL").toLocaleLowerCase()).Url;
                    this.supportInsurerTxt = this.translate.instant("COMPARE_SEND.SUPPORT_INSURER.TEXT").replace("[PARTICIPATING_INSURERS_URL]", this.eqaUrl.toLocaleLowerCase() + this.translate.instant("GLOBAL.PARTICIPATING_INSURER_URL").toLocaleLowerCase());
                    (this.quotesList).forEach((value) => {
                        value.quoterResponse.PolicyTypeTranslate = this.setPolicyTypeTranslation(value.quote.policytype, value.quoterResponse.PolicyType);
                        this.quotes.push(value.quoterResponse);
                    });
                    this.setPosition();
                    this.setInitSelection(languageChanged);
                } else {
                    this.clearAndStartOver(true);
                }
            }, 1000);
        }, 100);
    }

    setPosition() {
        let position = 1;
        (this.quotesList).forEach((value => {
            value.calcInput.position = position;
            position++;
        }));
    }

    setInitSelection(languageChanged) {
        const editedId = this.quoterRequestService.getEditCalculationId();
        if ((editedId || editedId == 0) && languageChanged !== true) {
            this.selectCalculation(editedId, null);
        } else {
            if (languageChanged) {
                const ids = JSON.parse(JSON.stringify(this.ids));
                this.ids.length = 0;
                for (let value of ids) {
                    this.selectCalculation(value, null);
                }
            } else {
                this.ids.length = 0;
                this.selectCalculation(this.quotes.length - 1, null);
            }
        }
    }

    //an area broken, this is a corrective routine but the real solution would be to fix the data prior
    setPolicyTypeTranslation(policytype, policyTypeName): string {
        if (policytype !== 1) {
            if (policytype === 4) {
                return (policyTypeName === 'MobileStandard' || policyTypeName === 'Mobilehome') ?
                    this.translate.instant("GLOBAL.Mobilehome") :
                    this.translate.instant("GLOBAL.Mobilehome Choice");
            }
            else {
                return policytype === 2 ? this.translate.instant("GLOBAL.Renters") : this.translate.instant("GLOBAL.CONDO_OWNERS");
            }
        }
        else {
            if (["Homeowners", "HomeStandard"].indexOf(policyTypeName) !== -1) {
                return this.translate.instant("GLOBAL.Homeowners");
            }
            else {
                return this.translate.instant("GLOBAL.Homeowners Choice");
            }
        }
    }

    private reload() {
        setTimeout(() => this._reload = false);
        setTimeout(() => this._reload = true);
    }

    goBack() {
        if (this.quotesList.length !== 0) {
            const id = this.quotesList.length - 1;
            this.quoterRequestService.setEditCalculationId(id);
            this.quoteStorageService.SaveCalcInputRequest(this.quotesList[id].calcInput);
            this.quoterRequestService.saveQuoterRequest(this.quotesList[id].quote);
            if (this.quotesList[id].calcInput.yesCompare) {
                this.router.navigate([this.getUrlByPolicyType(this.quotesList[id].quote.policytype) + this.quotesList[id].quote.policytype], { queryParams: { yesCompare: true } });
            } else {
                this.router.navigate([this.getUrlByPolicyType(this.quotesList[id].quote.policytype) + this.quotesList[id].quote.policytype]);
            }
        } else {
            this.quoterRequestService.setEditCalculationId(null);
            this.location.back();
        }
    }

    yesCompare(policy: string) {
        if (this.quotesList.length < 10) {
            this.quoterRequestService.setEditCalculationId(null);
            this.quoteStorageService.SaveCompareItem('true');
            this.quoterRequestService.setAddNewCalc(true);
            const editedId = this.ids[0];
            this.quoteStorageService.SaveCalcInputRequest(this.quotesList[editedId].calcInput);
            this.quoterRequestService.saveQuoterRequest(this.quotesList[editedId].quote);
            const yesCompare = policy.toLowerCase().indexOf('choice') > -1 ? true : false;
            if (policy.toLowerCase().indexOf('mobilehome') > -1) {
                this.router.navigate(["mobilehome/general"], { queryParams: { showGeneralPage: false, yesCompare: yesCompare } });
            } else {
                this.router.navigate(["homeowner/general"], { queryParams: { showGeneralPage: false, yesCompare: yesCompare } });
            }
        } else {
            this.openHelperPopup("COMPARE_SEND.COMPARE_TABLE.MAX_LIMIT", "COMPARE_SEND.COMPARE_TABLE.MAX_LIMIT");
        }
    }

    getCompleteAddress() {
        let address = "";
        if (this.quote.StreetAddress) {
            address += this.quote.StreetAddress + ", ";
        }
        if (this.quote.Address2) {
            address += this.quote.Address2 + ", ";
        }
        if (this.quote.City) {
            address += this.quote.City + ", ";
        }
        if (this.quote.AddressState) {
            address += this.quote.AddressState + " ";
        }
        if (this.quote.ZipCode) {
            address += this.quote.ZipCode;
        }
        return address;
    }

    editCalculation(id, event) {
        if (event) {
            event.preventDefault();
        }
        this.quoterRequestService.setEditCalculationId(id);
        this.quoteStorageService.SaveCalcInputRequest(this.quotesList[id].calcInput);
        this.quoterRequestService.saveQuoterRequest(this.quotesList[id].quote);
        this.quoteStorageService.SaveQuoteCompare(false);

        if (this.quotesList[id].calcInput.yesCompare) {
            this.router.navigate([this.getUrlByPolicyType(this.quotesList[id].quote.policytype) + this.quotesList[id].quote.policytype], { queryParams: { yesCompare: true } });
        } else {
            this.router.navigate([this.getUrlByPolicyType(this.quotesList[id].quote.policytype) + this.quotesList[id].quote.policytype]);
        }
    }

    getUrlByPolicyType(policy) {
        let url = "";
        if (policy === 2) {
            url = "renter/calculations/";
        }
        if (policy === 1) {
            url = "homeowner/calculations/";
        }
        if (policy === 4) {
            url = "mobilehome/calculations/";
        }
        if (policy === 3) {
            url = "condo/calculations/";
        }

        return url;
    }

    setPage(page) {
        if (this.pages >= page && 0 < page) {
            this.page = page;
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.screenWidth = window.innerWidth;

        const width = window.innerWidth;
        if (width >= 672) {
            this.perPage = 4;
        } else if (width >= 554) {
            this.perPage = 3;
        } else if (width >= 434) {
            this.perPage = 2;
        } else {
            this.perPage = 1;
        }
        this.pages = Math.ceil(this.ids.length / this.perPage);
        this.setPage(1)
    }

    isQuote2016 = null;
    isQuote2019 = null;

    sortEstimates(id) {
        if (this.ids.indexOf(id) === -1) {
            if (id != -1) {
                this.ids.push(id);
                this.ids.sort((a, b) => (a - b));
            }

        } else {
            const index = this.ids.indexOf(id);
            if (index > -1) {
                this.ids.splice(index, 1);
            }
        }
    }

    selectEstimateMessage(id, titleTransKey, msgTransKey) {
        this.openHelperPopup(titleTransKey, msgTransKey);
        const ind = this.ids.indexOf(id);
        if (ind > -1) {
            this.ids.splice(ind, 1);
        }
    }

    selectCalculation(id, event) {
        if (event) {
            event.preventDefault();
        }

        this.sortEstimates(id);
        this.table = [];
        this.compareCollection = new Array(this.ids.length);

        if (!this.quotesList || this.quotesList.length === 0) {
            this.setShowTable(false);
            return;
        }

        let policy = 0;
        let validSelection = this.buildCompare();
        if (!validSelection) {
            this.policy = 0;
            this.selectEstimateMessage(id, "COMPARE_SEND.COMPARE_POLICIES_WARNING_TITLE", "COMPARE_SEND.COMPARE_POLICIES_WARNING");
            return;
        }

        this.policy = policy !== 0 ? policy : this.policy;
        this.table = [];
        if (this.policy === 3) {
            this.generateTableCondoTable();
        } else if (this.policy === 1) {
            this.generateTableHomeTable(this.deductible);
        } else if (this.policy === 2) {
            this.generateTableRenterTable();
        } else if (this.policy === 4) {
            this.generateTableMobileTable();
        }

        this.setShowTable(validSelection && this.compareCollection.length > 0);
    }

    buildCompare(): boolean {
        let validSelection = true;
        this.syncSelected();
        (this.ids).forEach((value, index) => {
            let compareProperties: CompareProperties = {
                calcInput: this.quotesList[value].calcInput,
                quote: this.quotesList[value].quote,
                MonthlyPremium: this.quotesList[value].quoterResponse.MonthlyPremium,
                AnnualPremium: this.quotesList[value].quoterResponse.AnnualPremium,
                quoterResponse: this.quotesList[value].quoterResponse
            };

            if (index === 0) {
                this.firstSelectedPolicy = compareProperties.quote.policytype;
                this.firstSelectedPolicyDate = compareProperties.quote.ActualStartDate;
            }

            this.compareCollection[index] = (compareProperties);
            this.deductible = compareProperties.calcInput.PersonalPropertyDeductible;
            compareProperties.calcInput.PolicyName = compareProperties.calcInput.PersonalPropertyDeductible > 0 ? "Home Owners Choice" : "Home Owners";
            if (this.ids.length === 1) {
                this.policy = compareProperties.quote.policytype;
            }
            else {
                if (this.firstSelectedPolicy === compareProperties.quote.policytype) {
                    this.policy = this.firstSelectedPolicy;
                }
                else {
                    validSelection = false;
                    return;
                }
            }
        });

        return validSelection;
    }

    setShowTable(show) {
        setTimeout(() => {
            this.showTable = show;
        }, 1000);
    }

    openHelperPopup(title: string, text: string, text2: string = null, text3: string = null) {
        const header = this.translate.instant(title);
        let body = this.translate.instant(text);

        if (text2 !== null) {
            body += this.translate.instant(text2);

            if (text3 !== null) {
                body += this.translate.instant(text3);
            }
        }
        const data = {
            header,
            body,
            showTopCloseButton: false
        };
        const size = "xl";
        this.openModal(data, size);
    }

    openModal(data, size) {
        const modalRef = this.modalService.open(PopupComponent,
            {
                scrollable: false,
                windowClass: "myCustomModalClass",
                size
                // keyboard: false,
                // backdrop: 'static'
            });
        modalRef.componentInstance.fromParent = data;
        modalRef.result.then();
    }

    removePolicy: number;
    removeCalculation(id, content, event) {
        if (event) {
            event.preventDefault();
        }

        this.removePolicy = id;
        const modalRef = this.modalService.open(content,
            {
                scrollable: false,
                windowClass: "myCustomModalClass",
                size: 'xl'
            });
        modalRef.result.then();
    }

    yesRemovePolicy(modal) {
        this.removeSelected(this.removePolicy);
        this.quoterRequestService.removeCalculationById(this.removePolicy);       

        this.quotes = [];
        setTimeout(() => {
            this.init(false);
            this.checkComparison();
        });

        modal.close();
    }

    removeSelected(quoteListIndex) {
        let ret = [];

        for (let id of this.ids) {
            if (id !== quoteListIndex) {
                ret.push(id > quoteListIndex ? id - 1 : id);
            }
        }

        this.ids = ret;
    }

    syncSelected() {
        let ret = [];

        for (let id of this.ids) {
            if (this.quotesList[id]) {
                ret.push(id);
            }
        }

        this.ids = ret;
    }

    showCompare() {
        if (this.quotesList == null || this.quotesList.length == 0) {
            return false;
        }

        const calcInput = this.quotesList[this.ids.length === 1 ? this.ids[0] : 0].calcInput;
        if (this.policy === this.modules.homeowner) {
            return !calcInput.PersonalPropertyDeductible || (calcInput.PersonalPropertyDeductible !== 0 &&
                calcInput.PersonalPropertyDeductible !== null);
        }

        if (this.policy === this.modules.mobilehome) {
            return true;
        }

        return false;
    }

    getPolicyNameById(policy) {
        let policyType = "";
        const calcInput = this.quotesList[this.ids.length === 1 ? this.ids[0] : 0].calcInput;
        if (policy === 2) {
            policyType = "GLOBAL.Renters";
        }

        if (policy === this.modules.homeowner && !calcInput.PersonalPropertyDeductible && calcInput.PersonalPropertyCoverage) {
            policyType = "GLOBAL.Homeowners";
        }
        else if (policy === this.modules.homeowner && (!calcInput.PersonalPropertyCoverage || (calcInput.PersonalPropertyDeductible !== 0 && calcInput.PersonalPropertyDeductible !== null))) {
            policyType = "GLOBAL.Homeowners Choice";
        }
        else if (policy === this.modules.mobilehome && !calcInput.PersonalPropertyDeductible && calcInput.PersonalPropertyCoverage) {
            policyType = "GLOBAL.Mobilehome";
        }
        else if (policy === this.modules.mobilehome && (!calcInput.PersonalPropertyCoverage || (calcInput.PersonalPropertyDeductible !== 0 && calcInput.PersonalPropertyDeductible !== null))) {
            policyType = "GLOBAL.Mobilehome Choice";
        }
        if (policy === 3) {
            policyType = "GLOBAL.CONDO_OWNERS";
        }
        return this.translate.instant(policyType);
    }


    getPolicyNameByIdYesCompare(policy) {
        let policyType = "";
        const calcInput = this.quotesList[this.ids.length === 1 ? this.ids[0] : 0].calcInput;
        if (policy === 2) {
            policyType = "Renters";
        }

        if (policy === this.modules.homeowner && !calcInput.PersonalPropertyDeductible && calcInput.PersonalPropertyCoverage) {
            policyType = "Homeowners";
        }
        else if (policy === this.modules.homeowner && (!calcInput.PersonalPropertyCoverage || (calcInput.PersonalPropertyDeductible !== 0 && calcInput.PersonalPropertyDeductible !== null))) {
            policyType = "Homeowners Choice";
        }
        else if (policy === this.modules.mobilehome && !calcInput.PersonalPropertyDeductible && calcInput.PersonalPropertyCoverage) {
            policyType = "Mobilehome";
        }
        else if (policy === this.modules.mobilehome && (!calcInput.PersonalPropertyCoverage || (calcInput.PersonalPropertyDeductible !== 0 && calcInput.PersonalPropertyDeductible !== null))) {
            policyType = "Mobilehome Choice";
        }
        if (policy === 3) {
            policyType = "Condo Owners";
        }
        return policyType;
    }

    getOppositePolicyNameById() {
        const calcInput = this.quotesList[this.ids.length === 1 ? this.ids[0] : 0].calcInput;
        let policyType = "";
        if (this.policy === this.modules.renter) {
            policyType = "GLOBAL.Renters";
        } else if (this.policy === this.modules.homeowner && !calcInput.PersonalPropertyDeductible && calcInput.PersonalPropertyCoverage) {
            policyType = "COMPARE_SEND.COMPARE_TO_HOMEOWNER_CHOICE";
        }
        else if (this.policy === this.modules.homeowner && (!calcInput.PersonalPropertyCoverage || (calcInput.PersonalPropertyDeductible !== 0 && calcInput.PersonalPropertyDeductible !== null))) {
            policyType = "COMPARE_SEND.COMPARE_TO_HOMEOWNER";
        }
        else if (this.policy === this.modules.mobilehome && !calcInput.PersonalPropertyDeductible && calcInput.PersonalPropertyCoverage) {
            policyType = "COMPARE_SEND.COMPARE_TO_MOBILE_CHOICE";
        }
        else if (this.policy === this.modules.mobilehome && (!calcInput.PersonalPropertyCoverage || (calcInput.PersonalPropertyDeductible !== 0 && calcInput.PersonalPropertyDeductible !== null))) {
            policyType = "COMPARE_SEND.COMPARE_TO_MOBILE";
        } else if (this.policy === this.modules.condo) {
            policyType = "GLOBAL.CONDO_OWNERS";
        }
        return this.translate.instant(policyType);
    }

    getYesComparePolicyNameById() {
        let policyType = "COMPARE_SEND.COMPARE_HOMEOWNER";
        const calcInput = this.quotesList[this.ids.length === 1 ? this.ids[0] : 0].calcInput;

        if (this.policy === this.modules.homeowner && (!calcInput.PersonalPropertyCoverage || (calcInput.PersonalPropertyDeductible !== 0 && calcInput.PersonalPropertyDeductible !== null))) {
            policyType = "COMPARE_SEND.COMPARE_HOMEOWNER_CHOICE";
        }
        else if (this.policy === this.modules.mobilehome && !calcInput.PersonalPropertyDeductible && calcInput.PersonalPropertyCoverage) {
            policyType = "COMPARE_SEND.COMPARE_MOBILEHOME";
        }
        else if (this.policy === this.modules.mobilehome && (!calcInput.PersonalPropertyCoverage || (calcInput.PersonalPropertyDeductible !== 0 && calcInput.PersonalPropertyDeductible !== null))) {
            policyType = "COMPARE_SEND.COMPARE_MOBILEHOME_CHOICE";
        }
        return this.translate.instant(policyType);
    }

    getPolicyNav(policy) {
        if (policy == 2) {
            return "renter";
        } else if (policy === 3) {
            return "condo";
        } else if (policy === 4) {
            return "mobilehome";
        }
        else {
            return "homeowner";
        }
    }

    clearAndStartOver(home) {
        this.ids.length = 0;
        this.quoterRequestService.resetAllData();
        this.quoterRequestService.deleteAllCalculations();
        this.quoterRequestService.deleteCurrentCalculations();
        this.quoterRequestService.setEditCalculationId(null);
        this.errorMessageService.moveTop();
        this.router.navigate([""]);
    }

    addNewCompare() {
        this.quoterRequestService.setEditCalculationId(null);
        this.quoterRequestService.setAddNewCalc(true);
        this.router.navigate([this.getPolicyNav(this.quote.policytype) + "/calculations/" + this.quote.policytype]);
    }

    generateTableCondoTable() {
        const tableProperties = [
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.POLICY_DETAILS"),
                value: this.translate.currentLang === 'en' ? this.translate.instant("GLOBAL.Condo") : '',
                type: "norm",
                class: "first",
                valueClass: "first",
                key: "policyDetail"
            },
            {
                key: "policyType",
                label: this.translate.instant("COMPARE_SEND.FIELDS.POLICY_TYPE"),
                value: this.translate.currentLang === 'en' ? this.translate.instant("COMPARE_SEND.OWNERS") : this.translate.instant("GLOBAL.CONDO_OWNERS"),
                type: "index",
                valueClass: "first"
            },
            {
                key: "zipCode",
                label: this.translate.instant("COMPARE_SEND.FIELDS.ZIP"), value: "ZipCode", type: "quote"
            },
            {
                key: "estimateDate",
                label: this.translate.instant("COMPARE_SEND.FIELDS.ESTIMATE_DATE"),
                value: moment().format("MM/DD/YYYY"),
                type: "norm"
            },
            {
                key: "estimateStart",
                label: this.translate.instant("COMPARE_SEND.FIELDS.EFFECTIVE_START"),
                value: "PolicyEffectiveDate",
                type: "calcInput",
                detailType: "date"
            },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.BUILDING_PROPERTY"), value: "", type: "", class: "first" },
            {
                key: "dwellingCoverage",
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"),
                value: "BuildingPropertyCoverage",
                type: "calcInput",
                unit: "$"
            },
            {
                key: "dwellingDeductibleAmount",
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " ($)",
                value: "",
                type: "Calculate",
                m1: "BuildingPropertyCoverage",
                m1type: "calcInput",
                m2: "BuildingPropertyDeductible",
                m2type: "calcInput",
                unit: "$"
            },
            {
                key: "dwellingDeductiblePercent",
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " (%)",
                value: "BuildingPropertyDeductible",
                type: "calcInput",
                unit: "%"
            },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.PERSONAL_PROPERTY"), value: "", type: "", class: "first" },
            {
                key: "personalPropertyCoverage",
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"),
                value: "PersonalPropertyCoverage",
                type: "calcInput",
                unit: "$"
            },
            {
                key: "personalPropertyDeductibleAmount",
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " ($)",
                value: "",
                type: "Calculate",
                m1: "PersonalPropertyCoverage",
                m1type: "calcInput",
                m2: "PersonalPropertyDeductible",
                m2type: "calcInput",
                unit: "$"
            },
            {
                key: "personalPropertyDeductiblePercent",
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " (%)",
                value: "PersonalPropertyDeductible",
                type: "calcInput", unit: "%"
            },
            {
                key: "breakableCoverage",
                label: this.translate.instant("COMPARE_SEND.FIELDS.BREAKABLE_COVERAGE"),
                value: "BreakablesCoverage",
                type: "calcInput",
                detailtype: "boolean",
                displaytrueValue: this.translate.instant("GLOBAL.YES"),
                displayfalseValue: this.translate.instant("GLOBAL.NO")
            },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.LOSS_OF_USE"), value: "", type: "", class: "first" },
            {
                key: "lossOfUseCoverage",
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"), value: "LossOfUseCoverage", type: "calcInput", unit: "$"
            },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.LOSS_ASSESSMENT"), value: "", type: "", class: "first" },
            {
                key: "lossAssessmentCoverage",
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"), value: "LossAssessmentCoverage", type: "calcInput", unit: "$"
            },
            {
                key: "lossAssessmentDeductibleAmount",
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " ($)", value: "LossAssessmentDeductible", type: "quoterResponse", unit: "$"
            },
            {
                key: "lossAssessmentDeductiblePercent",
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " (%)",
                value: "LossAssessmentDeductible",
                type: "calcInput", unit: "%"
            },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.PREMIUM"), value: "", type: "", class: "first" },
            { key: "premiumMonthly", label: this.translate.instant("COMPARE_SEND.FIELDS.MONTHLY"), value: "MonthlyPremium", type: "summary", unit: "$" },
            { key: "premiumAnnual", label: this.translate.instant("COMPARE_SEND.FIELDS.ANNUAL"), value: "AnnualPremium", type: "summary", unit: "$" },
        ];
        this.generateTable(this.frfTable(tableProperties));
    }

    frfTable(tableProperties) {
        //prune frfMode disabled fields here
        if (this.compareCollection.length > 0) {
            if (this.isTableFrfMode()) {
                const breakableIndex = tableProperties.map(function (e) { return e.key; }).indexOf('breakableCoverage');
                if (breakableIndex && breakableIndex > -1) {
                    tableProperties.splice(breakableIndex, 1);
                }
                const masonryIndex = tableProperties.map(function (e) { return e.key; }).indexOf('masonryCoverage');
                if (masonryIndex && masonryIndex > -1) {
                    tableProperties.splice(masonryIndex, 1);
                }
            }
        }

        return tableProperties;
    }

    isTableFrfMode() {
        if (this.compareCollection.length == 0) {
            return false;
        }

        for (const compare of this.compareCollection) {
            if (compare && !this.calcDatesService.isFrfMode(compare.quote.ActualStartDate, compare.quote.IsRenewal)) {
                return false;
            }
        }

        return true;
    }

    buildPdf() {
        let model = new DownloadFilePostModel();
        model.quotes = [];
        let isHeader = false;

        this.setPosition();

        if (this.quotesList && this.quotesList.length !== 0) {
            (this.ids).forEach((value, index) => {
                const estimate = this.estimateBuilderService.BuildEstimate(this.quotesList[value].calcInput, this.quotesList[value].quote, this.quotesList[value].quoterResponse);
                if (!isHeader) {
                    if ((this.isTableFrfMode() && estimate.IsFrfMode) ||
                        (!this.isTableFrfMode() && !estimate.IsFrfMode)) {
                        model.header = this.printBuilderService.buildHeader(estimate);
                        model.label = this.printBuilderService.buildLabel(estimate);
                        isHeader = true;
                    }
                }

                model.quotes.push(this.printBuilderService.buildQuotePdf(estimate, true));
            });
        }
        return model;
    }

    generateTable(tbl) {
        this.table = [];

        if (this.ids.length === 0) {
            this.firstSelectedPolicy = null;
            this.policy = null;
            this.showTable = false;
            return false;
        }



        this.onResize('');
        for (let i = 0; i < tbl.length; i++) {
            const obj: { [k: string]: any } = {};
            obj.label = tbl[i].label;
            if (tbl[i].class === "first") {
                obj.label += ":";
            }
            obj.key = tbl[i].key;
            obj.class = tbl[i].class;
            obj.valueClass = tbl[i].valueClass;
            for (let j = 0; j < this.ids.length; j++) {
                if (tbl[i].type === "") {
                    obj["value" + j] = "";
                } else if (tbl[i].type === "summary") {
                    const v = this.compareCollection[j][tbl[i].value] ? this.compareCollection[j][tbl[i].value] : 0;
                    obj["value" + j] = this.numberWithCommas(v.toFixed(2), true);
                    if (tbl[i].unit === "$") {
                        obj["value" + j] = tbl[i].unit + obj["value" + j];
                    }
                } else if (tbl[i].type === "calcInput" && tbl[i].detailtype === "boolean" && (tbl[i].key === 'breakableCoverage' || tbl[i].key === 'masonryCoverage')) {
                    if (this.calcDatesService.isFrfMode(this.compareCollection[j].quote.ActualStartDate, this.compareCollection[j].quote.IsRenewal)) {
                        obj["value" + j] = this.translate.instant("COMPARE_SEND.FIELDS.NA");
                    }
                    else {
                        obj["value" + j] = this.compareCollection[j][tbl[i].type][tbl[i].value] === true ?
                            tbl[i].displaytrueValue :
                            tbl[i].displayfalseValue;
                    }

                } else if (tbl[i].type === "calcInput" && tbl[i].detailType === undefined) {
                    if (this.compareCollection[j][tbl[i].type][tbl[i].value] !== null) {
                        obj["value" + j] = this.numberWithCommas(this.compareCollection[j][tbl[i].type][tbl[i].value]);
                        if (tbl[i].unit === "$") {
                            obj["value" + j] = obj["value" + j] === "" ? tbl[i].unit + "0" : tbl[i].unit + obj["value" + j];
                        } else if (tbl[i].unit === "%") {
                            if ((this.policy === 1 || this.policy === 4) && tbl[i].key === 'personalPropertyDeductiblePercent'
                                && (this.compareCollection[j][tbl[i].type][tbl[i].value] === 0 && this.compareCollection[j].calcInput.PersonalPropertyDeductible == 0 && this.compareCollection[j].calcInput?.PersonalPropertyCoverage != 0)) {
                                obj["value" + j] = this.translate.instant("COMPARE_SEND.FIELDS.NA");
                            } else if ((this.policy === 1 || this.policy === 4) && tbl[i].key === 'personalPropertyDeductiblePercent' && this.compareCollection[j].calcInput?.PersonalPropertyCoverage == 0) {
                                obj["value" + j] = '-';
                            } else { obj["value" + j] = (this.policy === 3 && obj["value" + j] === "") ? '-' : obj["value" + j] + tbl[i].unit; }
                        }

                    } else {
                        if ((this.policy === 1 || this.policy === 4) && tbl[i].key === 'personalPropertyDeductiblePercent') {
                            obj["value" + j] = this.compareCollection[j].calcInput.yesCompare ? this.translate.instant("COMPARE_SEND.FIELDS.NA") : '-';
                        } else {
                            if (tbl[i].unit === "$") {
                                obj["value" + j] = tbl[i].unit + "0";
                            } else if (tbl[i].unit === "%") {
                                obj["value" + j] = '-';
                            }
                        }
                    }

                } else if (tbl[i].type === "calcInput" && tbl[i].detailType === "date") {
                    obj["value" + j] = this.compareCollection[j][tbl[i].type][tbl[i].value];
                } else if (tbl[i].type === "quote" && (tbl[i].detailtype === undefined) && tbl[i].unit === undefined) {
                    switch (tbl[i].key) {
                        case 'foundationType':
                            obj["value" + j] = this.getFoundation(this.compareCollection[j][tbl[i].type][tbl[i].value])
                            break;
                        case 'frame':
                            obj["value" + j] = this.compareCollection[j][tbl[i].type][tbl[i].value] ? this.translate.instant("GLOBAL.YES") : this.translate.instant("GLOBAL.NO");
                            break;
                        case 'roofTypeVariable':
                            obj["value" + j] = this.getRoofType(this.compareCollection[j][tbl[i].type][tbl[i].value]);
                            break;
                        case 'stories':
                            obj["value" + j] = (this.compareCollection[j][tbl[i].type][tbl[i].value] === 0 ? this.translate.instant("HOMEOWNERS.QUESTIONS.STORIES.STORIES_SINGLE") : this.translate.instant("HOMEOWNERS.QUESTIONS.STORIES.STORIES_GREATER_THAN_ONE"));
                            break;
                        default:
                            obj["value" + j] = this.compareCollection[j][tbl[i].type][tbl[i].value];

                    }

                } else if (tbl[i].type === "quote" && tbl[i].detailtype === "boolean") {
                    if (this.policy === 4) {
                        if (tbl[i].key === 'hazardStatus') {
                            let hazardStatus = '';
                            if (this.compareCollection[j].quote.HasBracingSystem === true) {
                                hazardStatus = this.compareCollection[j].quote.HasInspection === true ?
                                    this.translate.instant("COMPARE_SEND.FIELDS.MAY_QUALIFY") : this.translate.instant("COMPARE_SEND.FIELDS.DOES_NOT_QUALIFY");
                            } else {
                                hazardStatus = this.translate.instant("COMPARE_SEND.FIELDS.DOES_NOT_QUALIFY");
                            }
                            obj["value" + j] = hazardStatus;
                        } else if (tbl[i].key === 'hazardDiscount') {
                            let hazardDiscount = '';
                            if (this.compareCollection[j].quote.HasBracingSystem === true) {
                                hazardDiscount = this.compareCollection[j].quote.HasInspection === true ? "21%" : "";
                            }
                            obj["value" + j] = hazardDiscount;
                        } else {
                            obj["value" + j] = this.compareCollection[j][tbl[i].type][tbl[i].value] === true ?
                                tbl[i].displaytrueValue :
                                tbl[i].displayfalseValue;
                        }

                    } else if (this.policy === 1) {
                        if (tbl[i].key === 'hazardStatus') {
                            // tslint:disable-next-line:max-line-length
                            let hazardStatus = '';
                            if (this.compareCollection[j].quote.HrdRequirementsConfirmed && this.compareCollection[j].quote.HrdRequirementsVerifiedByEngineer) {
                                hazardStatus = this.translate.instant("COMPARE_SEND.FIELDS.MAY_QUALIFY");
                            } else {
                                hazardStatus = this.translate.instant("COMPARE_SEND.FIELDS.DOES_NOT_QUALIFY");
                            }
                            obj["value" + j] = hazardStatus;

                        } else if (tbl[i].key === 'hazardDiscount') {
                            // tslint:disable-next-line:max-line-length
                            let hazardDiscount = '';
                            if (this.compareCollection[j].quote.HrdRequirementsConfirmed && this.compareCollection[j].quote.HrdRequirementsVerifiedByEngineer) {
                                if (this.compareCollection[j].quote.foundationTypeVariable === 2) {
                                    // tslint:disable-next-line:radix
                                    let yearbuild = parseInt(this.compareCollection[j].quote.YearBuilt.toString());
                                    if (yearbuild >= 1940 && yearbuild <= 1979) {
                                        hazardDiscount = "10%";
                                        // tslint:disable-next-line:radix
                                    } else if (yearbuild < 1940) {
                                        hazardDiscount = "15%";
                                    }
                                }
                                if (this.compareCollection[j].quote.foundationTypeVariable === 1) {
                                    let yearbuild = parseInt(this.compareCollection[j].quote.YearBuilt.toString());
                                    if (yearbuild >= 1940 && yearbuild <= 1979) {
                                        hazardDiscount = "20%";
                                        // tslint:disable-next-line:radix
                                    } else if (yearbuild < 1940) {
                                        hazardDiscount = "25%";
                                    }
                                }
                            }
                            obj["value" + j] = hazardDiscount;

                        } else {
                            obj["value" + j] = this.compareCollection[j][tbl[i].type][tbl[i].value] === true ?
                                tbl[i].displaytrueValue :
                                tbl[i].displayfalseValue;
                        }
                    } else {

                        obj["value" + j] = this.compareCollection[j][tbl[i].type][tbl[i].value] === true ?
                            tbl[i].displaytrueValue :
                            tbl[i].displayfalseValue;
                    }

                } else if (tbl[i].type === "quote" && tbl[i].detailtype === undefined && tbl[i].unit !== undefined) {

                    obj["value" + j] = this.numberWithCommas(this.compareCollection[j][tbl[i].type][tbl[i].value]);
                    if (tbl[i].unit === "$") {
                        obj["value" + j] = tbl[i].unit + obj["value" + j];
                    }

                    if (tbl[i].unit === "%") {
                        obj["value" + j] = obj["value" + j] + tbl[i].unit;
                    }
                } else if (tbl[i].type === "Calculate") {
                    obj["value" + j] = this.numberWithCommas((this.compareCollection[j][tbl[i].m1type][tbl[i].m1]
                        * this.compareCollection[j][tbl[i].m2type][tbl[i].m2]) / 100);
                    if (tbl[i].unit === "$") {
                        if (this.policy === 1 || this.policy === 4) {
                            if (obj["value" + j] === '' && this.compareCollection[j].calcInput.PersonalPropertyDeductible == 0 && this.compareCollection[j].calcInput?.PersonalPropertyCoverage != 0) {
                                obj["value" + j] = this.translate.instant("COMPARE_SEND.FIELDS.NA");
                            } else if (obj["value" + j] === '' && this.compareCollection[j].calcInput?.PersonalPropertyCoverage == 0) {
                                obj["value" + j] = '-';
                            } else {
                                obj["value" + j] = tbl[i].unit + obj["value" + j];
                            }
                        } else {
                            obj["value" + j] = obj["value" + j] === '' ? '-' : tbl[i].unit + obj["value" + j];
                        }
                    }

                    if (tbl[i].unit === "%") {
                        obj["value" + j] = obj["value" + j] === '' ? '-' : obj["value" + j] + tbl[i].unit;
                    }
                } else if (tbl[i].type === "norm") {
                    if (tbl[i].key === 'policyDetail') {
                        if (this.policy === 1) {
                            const calcInput = this.compareCollection[j].calcInput;
                            const policyType = (!calcInput.PersonalPropertyCoverage ||
                                (calcInput.PersonalPropertyDeductible !== 0 &&
                                    calcInput.PersonalPropertyDeductible !== null)) ?
                                this.translate.instant("GLOBAL.Homeowners") : '';
                            obj["value" + j] = policyType;
                        } else if (this.policy === 4) {
                            const calcInput = this.compareCollection[j].calcInput;
                            const policyType = (!calcInput.PersonalPropertyCoverage ||
                                (calcInput.PersonalPropertyDeductible !== 0 &&
                                    calcInput.PersonalPropertyDeductible !== null)) ?
                                this.translate.instant("GLOBAL.Mobilehome") : '';
                            obj["value" + j] = policyType;
                        } else {
                            obj["value" + j] = [tbl[i].value];
                        }
                    } else {
                        obj["value" + j] = [tbl[i].value];
                    }
                } else if (tbl[i].type === "quoterResponse" && tbl[i].detailType === undefined) {
                    if (this.compareCollection[j][tbl[i].type][tbl[i].value] !== null) {
                        obj["value" + j] = this.numberWithCommas(this.compareCollection[j][tbl[i].type][tbl[i].value]);
                        if (tbl[i].unit === "$") {
                            obj["value" + j] = obj["value" + j] === "" ? '-' : tbl[i].unit + obj["value" + j];
                        } else if (tbl[i].unit === "%") {
                            obj["value" + j] = obj["value" + j] === "" ? '-' : obj["value" + j] + tbl[i].unit;
                        }

                    } else {
                        if (tbl[i].unit === "$" || tbl[i].unit === "%") {
                            obj["value" + j] = '-';
                        }
                    }
                }

                if (tbl[i].type === "index") {
                    if (this.policy === 1) {
                        const calcInput = this.compareCollection[j].calcInput;
                        const policyType = (!calcInput.PersonalPropertyCoverage ||
                            (calcInput.PersonalPropertyDeductible !== 0 &&
                                calcInput.PersonalPropertyDeductible !== null)) ?
                            this.translate.instant("COMPARE_SEND.CHOICE") : this.translate.instant("GLOBAL.Homeowners");
                        obj["value" + j] = policyType + " #" + (calcInput.position);
                    } else if (this.policy === 4) {
                        const calcInput = this.compareCollection[j].calcInput;
                        const policyType = (!calcInput.PersonalPropertyCoverage ||
                            (calcInput.PersonalPropertyDeductible !== 0 &&
                                calcInput.PersonalPropertyDeductible !== null)) ?
                            this.translate.instant("COMPARE_SEND.CHOICE") : this.translate.instant("GLOBAL.Mobilehome");
                        obj["value" + j] = policyType + " #" + (calcInput.position);
                    } else {
                        obj["value" + j] = [tbl[i].value] + " #" + (this.compareCollection[j].calcInput.position);
                    }

                }
            }
            this.table.push(obj);
        }
    }

    numberWithCommas(x, isDecimal = false) {
        if (x) {
            const val = isDecimal ? x : Math.trunc(x);
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }

        return '';
    }

    trackAgentClick(url) {
        this.trackingService.eventEmitter(url, "FindYourAgent", "Click");
    }

    generateTableMobileTable() {
        const hrd = [
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.STATUS"),
                value: "HasBracingSystem",
                type: "quote",
                detailtype: "boolean", key: "hazardStatus", show: true
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DISCOUNT"),
                value: "HasBracingSystem",
                type: "quote",
                detailtype: "boolean",
                key: "hazardDiscount"
            }]
        const tableProperties = [
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.POLICY_DETAILS"),
                value: this.translate.instant("GLOBAL.Mobilehome"),
                type: "norm",
                class: "first",
                valueClass: "first",
                key: "policyDetail"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.POLICY_TYPE"),
                value: this.translate.instant("GLOBAL.Mobilehome"),
                type: "index",
                valueClass: "first",
                key: "policyType",
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.ZIP"), value: "ZipCode", type: "quote",
                key: "zipCode"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.BRACING_SYSTEM"), value: "HasBracingSystem", type: "quote",
                key: "HasBracingSystem",
                detailtype: "boolean",
                displaytrueValue: this.translate.instant("GLOBAL.YES"),
                displayfalseValue: this.translate.instant("GLOBAL.NO"),
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.ESTIMATE_DATE"),
                value: moment().format("MM/DD/YYYY"),
                type: "norm",
                key: "estimateDate"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.EFFECTIVE_START"),
                value: "PolicyEffectiveDate",
                type: "calcInput",
                detailType: "date",
                key: "estimateStart"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.YEAR_BUILT"), value: "YearBuilt", type: "quote",
                key: "yearBuilt"
            },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.DWELLING"), value: "", type: "", class: "first" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"),
                value: "InsuredValue",
                type: "quote",
                unit: "$",
                key: "dwellingCoverage"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " ($)",
                value: "",
                type: "Calculate",
                m1: "BuildingPropertyDeductible",
                m1type: "calcInput",
                m2: "InsuredValue",
                m2type: "quote",
                unit: "$",
                key: "dwellingDeductibleAmount"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " (%)",
                value: "BuildingPropertyDeductible",
                type: "calcInput",
                unit: "%",
                key: "dwellingDeductiblePercent"
            },

            { label: this.translate.instant("COMPARE_SEND.FIELDS.BUILDING_CODE_UPGRADE"), value: "", type: "", class: "first" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"),
                value: "BuildingCodeUpgradeLimitIncrease",
                type: "calcInput",
                unit: "$",
                key: "buildingCodeCoverage"
            },


            { label: this.translate.instant("COMPARE_SEND.FIELDS.PERSONAL_PROPERTY"), value: "", type: "", class: "first" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"),
                value: "PersonalPropertyCoverage",
                type: "calcInput",
                unit: "$",
                key: "personalPropertyCoverage"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " ($)",
                value: "",
                type: "Calculate",
                m1: "PersonalPropertyCoverage",
                m1type: "calcInput",
                m2: "PersonalPropertyDeductible",
                m2type: "calcInput",
                unit: "$",
                key: "personalPropertyDeductibleAmount"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " (%)",
                value: "PersonalPropertyDeductible",
                type: "calcInput", unit: "%",
                key: "personalPropertyDeductiblePercent"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.BREAKABLE_COVERAGE"),
                value: "BreakablesCoverage",
                type: "calcInput",
                detailtype: "boolean",
                displaytrueValue: this.translate.instant("GLOBAL.YES"),
                displayfalseValue: this.translate.instant("GLOBAL.NO"),
                key: "breakableCoverage"
            },


            { label: this.translate.instant("COMPARE_SEND.FIELDS.LOSS_OF_USE"), value: "", type: "", class: "first" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"),
                value: "LossOfUseCoverage",
                type: "calcInput",
                unit: "$",
                key: "lossOfUseCoverage"
            },

            { label: this.translate.instant("COMPARE_SEND.FIELDS.HAZARD_REDUCTION"), value: "", type: "", class: "first" },
            ...hrd,
            { label: this.translate.instant("COMPARE_SEND.FIELDS.PREMIUM"), value: "", type: "", class: "first" },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.MONTHLY"), value: "MonthlyPremium", type: "summary", unit: "$", key: "premiumMonthly" },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.ANNUAL"), value: "AnnualPremium", type: "summary", unit: "$", key: "premiumAnnual" },
        ];
        this.generateTable(this.frfTable(tableProperties));
    }

    generateTableRenterTable() {
        const tableProperties = [
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.POLICY_DETAILS"),
                value: '',//this.translate.instant("GLOBAL.Renter"),
                type: "norm",
                class: "first",
                valueClass: "first"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.POLICY_TYPE"),
                value: this.translate.instant("GLOBAL.Renter"),
                type: "index",
                valueClass: "first",
                key: "policyType"
            },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.ZIP"), value: "ZipCode", type: "quote", key: "zipCode" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.ESTIMATE_DATE"),
                value: moment().format("MM/DD/YYYY"),
                type: "norm",
                key: "estimateDate"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.EFFECTIVE_START"),
                value: "PolicyEffectiveDate",
                type: "calcInput",
                detailType: "date",
                key: "estimateStart"
            },

            { label: this.translate.instant("COMPARE_SEND.FIELDS.PERSONAL_PROPERTY"), value: "", type: "", class: "first" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"),
                value: "PersonalPropertyCoverage",
                type: "calcInput",
                unit: "$",
                key: "personalPropertyCoverage"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " ($)",
                value: "",
                type: "Calculate",
                m1: "PersonalPropertyCoverage",
                m2: "PersonalPropertyDeductible",
                m1type: "calcInput",
                m2type: "calcInput",
                unit: "$",
                key: "personalPropertyDeductibleAmount"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " (%)",
                value: "PersonalPropertyDeductible",
                type: "calcInput", unit: "%",
                key: "personalPropertyDeductiblePercent"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.BREAKABLE_COVERAGE"),
                value: "BreakablesCoverage",
                type: "calcInput",
                key: "breakableCoverage",
                detailtype: "boolean",
                displaytrueValue: this.translate.instant("GLOBAL.YES"),
                displayfalseValue: this.translate.instant("GLOBAL.NO")
            },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.LOSS_OF_USE"), value: "", type: "", class: "first" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"),
                value: "LossOfUseCoverage",
                type: "calcInput",
                unit: "$",
                key: "lossOfUseCoverage"
            },

            { label: this.translate.instant("COMPARE_SEND.FIELDS.PREMIUM"), value: "", type: "", class: "first" },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.MONTHLY"), value: "MonthlyPremium", type: "summary", unit: "$", key: "premiumMonthly" },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.ANNUAL"), value: "AnnualPremium", type: "summary", unit: "$", key: "premiumAnnual" },
        ];


        this.generateTable(this.frfTable(tableProperties));
    }


    generateTableHomeTable(deductible) {
        const tableProperties = [
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.POLICY_DETAILS"),
                value: this.translate.instant("GLOBAL.Homeowners"),
                type: "norm",
                class: "first",
                valueClass: "first",
                key: "policyDetail"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.POLICY_TYPE"),
                value: deductible > 0 ? this.translate.instant("GLOBAL.Choice") : this.translate.instant("GLOBAL.Homeowners"),
                type: "index",
                valueClass: "first",
                key: "policyType"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.ZIP"), value: "ZipCode", type: "quote",
                key: "zipCode"
            },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.YEAR_BUILT"), value: "YearBuilt", type: "quote", key: "yearBuilt" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.FOUNDATION_TYPE"), value: "foundationTypeVariable", type: "quote",
                key: "foundationType"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.ROOF_TYPE"), value: "roofTypeVariable", type: "quote",
                key: "roofTypeVariable"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.FRAME"), value: "IsWoodFrame", type: "quote",
                key: "frame"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.NUMBER_OF_STORIES"), value: "numberOfStories", type: "quote",
                key: "stories"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.MANSONRY_VENEER_COVERAGE"),
                value: "MasonryVeneerCoverage",
                type: "calcInput",
                detailtype: "boolean",
                displaytrueValue: this.translate.instant("GLOBAL.YES"),
                displayfalseValue: this.translate.instant("GLOBAL.NO"),
                key: "masonryCoverage"
            },

            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.ESTIMATE_DATE"),
                value: moment().format("MM/DD/YYYY"),
                type: "norm",
                key: "estimateDate"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.EFFECTIVE_START"),
                value: "PolicyEffectiveDate",
                type: "calcInput",
                detailType: "date",
                key: "estimateStart"
            },

            { label: this.translate.instant("COMPARE_SEND.FIELDS.DWELLING"), value: "", type: "", class: "first" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"),
                value: "InsuredValue",
                type: "quote",
                unit: "$",
                key: "dwellingCoverage"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " ($)",
                value: "",
                type: "Calculate",
                m1: "BuildingPropertyDeductible",
                m1type: "calcInput",
                m2: "InsuredValue",
                m2type: "quote",
                unit: "$",
                key: "dwellingDeductibleAmount"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " (%)",
                value: "BuildingPropertyDeductible",
                type: "calcInput",
                unit: "%",
                key: "dwellingDeductiblePercent"
            },

            { label: this.translate.instant("COMPARE_SEND.FIELDS.BUILDING_CODE_UPGRADE"), value: "", type: "", class: "first" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"),
                value: "BuildingCodeUpgradeLimitIncrease",
                type: "calcInput",
                unit: "$",
                key: "buildingCodeCoverage"

            },

            { label: this.translate.instant("COMPARE_SEND.FIELDS.PERSONAL_PROPERTY"), value: "", type: "", class: "first" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"),
                value: "PersonalPropertyCoverage",
                type: "calcInput",
                unit: "$",
                key: "personalPropertyCoverage"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " ($)",
                value: "",
                type: "Calculate",
                m1: "PersonalPropertyCoverage",
                m1type: "calcInput",
                m2: "PersonalPropertyDeductible",
                m2type: "calcInput",
                unit: "$",
                key: "personalPropertyDeductibleAmount"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " (%)",
                value: "PersonalPropertyDeductible",
                type: "calcInput", unit: "%",
                key: "personalPropertyDeductiblePercent"
            },


            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.BREAKABLE_COVERAGE"),
                value: "BreakablesCoverage",
                type: "calcInput",
                detailtype: "boolean",
                displaytrueValue: this.translate.instant("GLOBAL.YES"),
                displayfalseValue: this.translate.instant("GLOBAL.NO"),
                key: "breakableCoverage"
            },


            { label: this.translate.instant("COMPARE_SEND.FIELDS.LOSS_OF_USE"), value: "", type: "", class: "first" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"), value: "LossOfUseCoverage", type: "calcInput", unit: "$",
                key: "lossOfUseCoverage"
            },

            { label: this.translate.instant("COMPARE_SEND.FIELDS.HAZARD_REDUCTION"), value: "", type: "", class: "first" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.STATUS"),
                value: "HasBracingSystem",
                type: "quote",
                detailtype: "boolean",
                displaytrueValue: this.translate.instant("COMPARE_SEND.FIELDS.MAY_QUALIFY"),
                displayfalseValue: this.translate.instant("COMPARE_SEND.FIELDS.DOES_NOT_QUALIFY"),
                key: "hazardStatus"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DISCOUNT"),
                value: "HasBracingSystem",
                type: "quote",
                detailtype: "boolean",
                displaytrueValue: "21%",
                displayfalseValue: "",
                key: "hazardDiscount"
            },


            { label: this.translate.instant("COMPARE_SEND.FIELDS.PREMIUM"), value: "", type: "", class: "first" },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.MONTHLY"), value: "MonthlyPremium", type: "summary", unit: "$", key: "premiumMonthly" },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.ANNUAL"), value: "AnnualPremium", type: "summary", unit: "$", key: "premiumAnnual" }
        ];
        this.generateTable(this.frfTable(tableProperties));
    }

    useLanguage(selectedlanguage: string, event: Event) {
        if (event) {
            event.preventDefault();
        }

        this.translationService.useLanguage(selectedlanguage);
        this.quoteStorageService.SaveLanguage(this.translationService.currentLanguage);
        setTimeout(() => {
            this.init(true);
        }, 1000);
    }

    getReportType() {
        let reportType = "";
        if (this.policy === this.modules.renter) {
            reportType = "Renter";
        } else if (this.policy === this.modules.homeowner) {
            reportType = "Home2019";
        } else if (this.policy === this.modules.mobilehome) {
            reportType = "Mobilehome2019";
        } else if (this.policy === this.modules.condo) {
            reportType = "Condo";
        }
        return reportType;
    }

    print(event: Event) {
        event.preventDefault();
        this.trackingService.eventEmitter("location", "User Behavior", "Print");
        this.operation("print");
    }

    download(event: Event) {
        event.preventDefault();
        this.trackingService.eventEmitter("location", "User Behavior", "Download");
        this.operation("");
    }

    tableDataRearrangement() {

        const header: HeaderForHeader = {
            langCode: this.translationService.currentLanguage,
            packageKey: 0,
            reportAddress: this.getCompleteAddress(),
            propertyKey: 0,
            reportTitle: this.getCompleteAddress(),
            reportType: this.getReportType()
        };

        const label: LabelForPdf = new LabelForPdf();

        const quotes: any[] = [];
        this.ids.forEach((v, i) => {
            const quote: any = {};
            this.table.forEach((value, index) => {
                switch (value.key) {
                    case "policyType":
                        quote.policyType = (quote.policyType ? quote.policyType : '') + ' ' + value["value" + i];
                        break;
                    case "policyDetail":
                        quote.policyType = value["value" + i];
                        break;
                    case "zipCode":
                        quote.zipCode = value["value" + i];
                        break;
                    case "estimateDate":
                        quote.estimateDate = value["value" + i][0];
                        break;
                    case "estimateStart":
                        quote.estimateStart = value["value" + i];
                        break;
                    case "personalPropertyCoverage":
                        quote.personalPropertyCoverage = value["value" + i];
                        break;
                    case "personalPropertyDeductibleAmount":
                        quote.personalPropertyDeductibleAmount = value["value" + i];
                        break;
                    case "personalPropertyDeductiblePercent":
                        quote.personalPropertyDeductiblePercent = value["value" + i];
                        break;
                    case "breakableCoverage":
                        quote.breakableCoverage = value["value" + i];
                        break;
                    case "lossOfUseCoverage":
                        quote.lossOfUseCoverage = value["value" + i];
                        break;
                    case "premiumMonthly":
                        quote.premiumMonthly = value["value" + i];
                        break;
                    case "premiumAnnual":
                        quote.premiumAnnual = value["value" + i];
                        break;
                    case "dwellingCoverage":
                        quote.dwellingCoverage = value["value" + i];
                        break;
                    case "dwellingDeductibleAmount":
                        quote.dwellingDeductibleAmount = value["value" + i];
                        break;
                    case "dwellingDeductiblePercent":
                        quote.dwellingDeductiblePercent = value["value" + i];
                        break;

                    case "lossAssessmentCoverage":
                        quote.lossAssessmentCoverage = value["value" + i];
                        break;
                    case "lossAssessmentDeductibleAmount":
                        quote.lossAssessmentDeductibleAmount = value["value" + i];
                        break;
                    case "lossAssessmentDeductiblePercent":
                        quote.lossAssessmentDeductiblePercent = value["value" + i];
                        break;
                    case "yearBuilt":
                        quote.yearBuilt = value["value" + i];
                        break;
                    case "hazardDiscount":
                        quote.hazardDiscount = value["value" + i];
                        break;
                    case "hazardStatus":
                        if (value["value" + i]) {
                            quote.hazardStatus = value["value" + i];
                        }
                        break;
                    case "hazardHrdIndicator":
                        if (value["value" + i]) {
                            quote.hazardHrdIndicator = value["value" + i];
                            quote.hazardStatus = this.correctHazardStatus(quote.hazardHrdIndicator);
                        }
                        break;
                    case "buildingCodeCoverage":
                        quote.buildingCodeCoverage = value["value" + i];
                        break;
                    case "bracingSystem":
                        quote.bracingSystem = value["value" + i];
                        break;
                    case "masonryCoverage":
                        quote.masonryCoverage = value["value" + i];
                        break;
                    case "foundationType":
                        quote.foundationType = value["value" + i];
                        break;
                    case "frame":
                        quote.frame = value["value" + i];
                        break;
                    case "roofTypeVariable":
                        quote.roofType = value["value" + i];
                        break;
                    case "stories":
                        quote.stories = value["value" + i];
                        break;
                    case "HasBracingSystem":
                        quote.bracingSystem = value["value" + i];
                        break;
                }
            });
            quotes.push(quote);
        });

        this.comparepdf = {
            header,
            label,
            quotes
        };
    }

    correctHazardStatus(hazardHrdIndicator) {
        if ('合格' == hazardHrdIndicator || 'Qualified' == hazardHrdIndicator || 'Calificado' == hazardHrdIndicator) {
            return this.translate.instant("GLOBAL.YES");
        } else {
            return this.translate.instant("GLOBAL.NO");
        }
    }

    operation(type) {
        const comparePdf = this.buildPdf();
        this.printoutService.printAndDownloadPdf(comparePdf, type);

        this.quoterRequestService.logRequests(this.quoterRequestService.selectedQuotes(this.ids, this.quoterRequestService.getSavedQuotes()),
            this.quoterRequestService.getPolicy(this.ids, this.quoterRequestService.getSavedQuotes()), "print");
    }

    getValueFromTable(value, key, i) {
        return value.key === key ? value["value" + i] : "";
    }

    openHelperEmailPopup(event: Event) {
        this.trackingService.eventEmitter("location", "User Behavior", "Email");
        event.preventDefault();
        this.tableDataRearrangement();
        const size = "xl";
        this.quoterRequestService.comparePageSelectedId = this.ids;
        this.openEmailModal(size);
    }

    openEmailModal(size) {
        const modalRef = this.modalService.open(EmailPopupComponent,
            {
                scrollable: false,
                backdrop: 'static',
                windowClass: "myCustomModalClass",
                size
            });
        modalRef.result.then((result) => {
            // This is intentional
        }, (reason) => {
            // This is intentional
        });
        this.backToTop();
    }

    policyDiffPop() {
        const size = "xl";
        this.errorMessageService.moveTop();
        this.policyDiffPopdeep(size);
    }

    policyDiffPopdeep(size) {
        this.errorMessageService.moveTop();
        const modalRef = this.modalService.open(PolicyDiffComponent,
            {
                scrollable: false,
                backdrop: 'static',
                windowClass: "myCustomModalClass",
                size
            });
        modalRef.result.then((result) => {
            // This is intentional
        }, (reason) => {
            // This is intentional
        });
    }

    getFoundation(index) {
        const foundationTypeVariableOptions = [
            this.translate.instant("HOMEOWNERS.QUESTIONS.FOUNDATIONS.SLAB"),
            this.translate.instant("HOMEOWNERS.QUESTIONS.FOUNDATIONS.RAISED"),
            this.translate.instant("HOMEOWNERS.QUESTIONS.FOUNDATIONS.OTHER")
        ];

        return foundationTypeVariableOptions[index];
    }

    getRoofType(index) {
        const roofTypeVariableOptions = [
            this.translate.instant("HOMEOWNERS.QUESTIONS.ROOF_TYPES.TILE"),
            this.translate.instant("HOMEOWNERS.QUESTIONS.ROOF_TYPES.COMPOSITION"),
            this.translate.instant("HOMEOWNERS.QUESTIONS.ROOF_TYPES.WOODSHAKE"),
            this.translate.instant("HOMEOWNERS.QUESTIONS.ROOF_TYPES.OTHER")
        ];

        return roofTypeVariableOptions[index];
    }

    imageUrl(relative: string) {
        return this.imageService.ImageLoc(relative);
    }

    public backToTop() {
        this.errorMessageService.moveTop();
    }
}
