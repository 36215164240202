import { Injectable } from "@angular/core";
import { QuoterRequest } from "../../models/quoterRequest.model";
import { QuoteApplicationSettings } from "../../models/quoteApplicationSettings.model";
import moment from "moment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CalcInput, HeadersReq } from "../../models/CalcInput.model";
import { HelperService } from "../helper/helper.service";
import { Observable, EMPTY, forkJoin } from "rxjs";
import { QuoterResponse } from "../../models/QuoterResponse.model";
import { environment } from "../../../../environments/environment";
import { Quotes } from "../../models/quotes.model";
import { ComparePdfModel } from "../../models/ComparePdf.model";
import { map, catchError } from 'rxjs/operators';
import { ErrorMessageService } from '../error-message/error-message.service';
import { QuoteStorageService} from '../quote-storage/quote-storage.service';
import { moduleType } from "../../models/module.type";
import { CalcDatesService } from "../calc-dates/calc-dates.service";

export const QUOTER_2019_ENDDATE = "3/31/2022";
export const QUOTER_2016_ENDDATE = "06/30/2019";
export const QUOTER_STARTDATE = "07/01/2019";
export const QUOTER_ENDDATE = "12/31/2022";

@Injectable({
    providedIn: "root"
})
export class QuoterRequest2Service {
    quoterRequest: QuoterRequest = new QuoterRequest();
    quoteApplicationSettings: QuoteApplicationSettings = new QuoteApplicationSettings();
    calcInput: CalcInput = new CalcInput();
    quoterResponse: QuoterResponse = new QuoterResponse();
    policytype: number;
    quotes: Quotes[] = [];
    addNewCalc = false;
    comparePdfParam: ComparePdfModel;
    showSuccess: boolean = true;
    comparePageSelectedId: number[] = [];

    constructor(private http: HttpClient,
        private helper: HelperService,
        private quoteStorageService: QuoteStorageService,
        private calcDateService: CalcDatesService,
        private errorMessageService: ErrorMessageService) {
        this.quoteApplicationSettings.showRoofDropdown = false;
    }

    setEditCalculationId(id) {
        //to-do
        this.quoteStorageService.SaveQuote(id);
    }

    getEditCalculationId(): any {
        const editCalculationId = this.quoteStorageService.GetQuote();
        return typeof (editCalculationId) != 'undefined' && editCalculationId != null && editCalculationId !== 'null' ? parseInt(editCalculationId) : null;
    }

    setAddNewCalc(add) {
        this.addNewCalc = add;
    }

    getAddNewCalc() {
        return this.addNewCalc;
    }

    resetAllData() {
        this.quoterRequest = new QuoterRequest();
        this.quoteStorageService.SaveSession(null);
        this.quoteStorageService.RemoveRequest();
        this.quoteStorageService.RemoveCalcInputRequest();
    }

    removeLocalStorageItem(key) {
        this.quoteStorageService.RemoveCompareItem();
    }

    removeCalculationById(id) {
        this.quotes = this.quoteStorageService.GetQuoteList();
        this.quotes.splice(id, 1);
        this.quoteStorageService.SaveQuoteList(this.quotes);
    }

    deleteAllCalculations() {
        this.quotes = [];
        this.quoteStorageService.RemoveQuoteList();
    }

    deleteCurrentCalculations() {
        this.quoteStorageService.RemoveCalcInputRequest();
    }

    saveLastPolicyTypeExplored(policytype: number) {
        this.policytype = policytype;
    }

    getLastPolicyTypeExplored() {
        return this.policytype;
    }

    saveQuoterRequest(quoterRequestInputs: QuoterRequest) {
        this.quoterRequest = quoterRequestInputs;
        this.quoteStorageService.SaveRequest(this.quoterRequest);
    }

    hydrateQuoterRequest(quoterRequest: QuoterRequest) {
        if (quoterRequest) {
            this.quoterRequest.ActualStartDate = moment(quoterRequest.ActualStartDate);
        }

        return quoterRequest;
    }

    getSavedQuoterRequest() {
        const quoterRequest = this.quoteStorageService.GetRequest();
        if (quoterRequest !== null) {
            this.quoterRequest = quoterRequest;
            this.quoterRequest.ActualStartDate = moment(quoterRequest.ActualStartDate);
        }
        return this.quoterRequest;
    }

    getCurrentQuoterRequest() {
        if (this.quoterRequest?.ActualStartDate) {
            return this.quoterRequest;
        }
        else {
            return this.getSavedQuoterRequest();
        }
    }

    setAddress(address) {
        this.quoterRequest.StreetAddress = address;
    }

    getStreetAddress() {
        return this.quoterRequest.StreetAddress;
    }

    setSettingForQuestionPage(quoterRequestInputs: QuoterRequest) {
        if (quoterRequestInputs.ActualStartDate === null) {
            return false;
        }
        if (quoterRequestInputs.ActualStartDate) {
            quoterRequestInputs.ActualStartDate = moment(quoterRequestInputs.ActualStartDate);
        }
        
        this.quoteApplicationSettings.showRoofDropdown = true;
        this.quoteApplicationSettings.showYearBuilt = true;
        this.quoteApplicationSettings.showHasInspection = true;
        
    }

    getSettingForQuestionPage() {
        return this.quoteApplicationSettings;
    }

    getRoofTypeValue(roofTypeIndexValue): string {
        let RoofType = "";
        if (roofTypeIndexValue === 0) {
            RoofType = "Tile";
        } else if (roofTypeIndexValue === 1) {
            RoofType = "Composition";
        } else if (roofTypeIndexValue === 2) {
            RoofType = "WoodShake";
        } else if (roofTypeIndexValue === 3) {
            RoofType = "Other";
        }
        return RoofType;
    }

    getEndpoint(endpointName, policyDate) {
        // Read the configuration to setup the appropriate endpoint
        let quoterConfig;

        quoterConfig = this.calcDateService.getService(policyDate);

        let url = environment.quoterApi + quoterConfig.version + "/" + environment[endpointName];

        const result = { url, key: environment.ocp_apim_subscription_key, port: environment.quoterPort };
        return result;
    }

    getCalcInputData(): CalcInput {
        const calcInputData: CalcInput = this.quoteStorageService.GetCalcInputRequest();
        if (calcInputData === null) {
            return null;
        } else {
            return calcInputData;
        }
    }

    hydrateCalcInput(calcInput: any) {
        const cRequestData = calcInput as CalcInput;
        if (cRequestData === null) {
            return null;
        } else {
            return cRequestData;
        }
    }

    setSavedQuotes(id) {
        if (id === null) {
            if (!this.quotes || this.quotes === null) {
                this.quotes = [];
            }
            this.quotes.push({
                calcInput: this.calcInput,
                quoterResponse: this.quoterResponse,
                quote: this.quoterRequest
            });
        } else {
            if (this.quotes && this.quotes !== null) {
                this.quotes[id] = {
                    calcInput: this.calcInput,
                    quoterResponse: this.quoterResponse,
                    quote: this.quoterRequest
                };
            } else {
                this.quotes = [];
                this.quotes.push({
                    calcInput: this.calcInput,
                    quoterResponse: this.quoterResponse,
                    quote: this.quoterRequest
                });
            }
        }

        this.quoteStorageService.SaveQuoteList(this.quotes);
    }

    getSavedQuotes() {
        const quotes = this.quoteStorageService.GetQuoteList();
        this.quotes.length = 0;
        if (quotes) {
            quotes.forEach(q => {
                if (q) {
                    this.quotes.push(q)
                }
            });
        }
        return this.quotes;
    }

    GetEstimation(cRequest: CalcInput, policy: number, requestType: string): Observable<any> {
        const request = this.BuildEstimatation(cRequest, policy, requestType);
        const endpointConfig = this.BuildEndpoint(policy, request.PolicyEffectiveDate);

        if (!request.Headers.SessionId) {
            alert("Request SessionId is missing");
        }
        

        return this.GetEstimateObservable(request, endpointConfig, policy);
    }


    GetEstimateObservable(request, endpointConfig, policy) {
        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'ocp-apim-subscription-key': environment.ocp_apim_subscription_key
        });
        const options = {
            headers: headers
        };
        return this.http.post(endpointConfig.url, request, options).pipe(map(response => {
            if (policy === moduleType.homeowner || policy === moduleType.mobilehome) {
                this.calcInput.PersonalPropertyDeductible = this.calcInput.PersonalPropertyDeductible !== null ? this.calcInput.PersonalPropertyDeductible : 0;
                this.calcInput.PersonalPropertyCoverage = this.calcInput.PersonalPropertyCoverage !== null ? this.calcInput.PersonalPropertyCoverage : 0;
            }
            return this.EstimateResponse(response, policy);
        }),
            catchError((error) => {
                this.errorMessageService.showErrorMessage(error);
                return EMPTY;
            })
        );
    }

    selectedQuotes(ids: number[], quotes: Quotes[]): CalcInput[] {
        let ret = [] as CalcInput[];

        if (!quotes || quotes.length === 0) {
            return ret;
        }

        (ids).forEach((value, index) => {
            ret.push(quotes[value].calcInput);
        });

        return ret;
    }

    getPolicy(ids: number[], quotes: Quotes[]): number {
        let ret = 0;

        if (!quotes || quotes.length === 0) {
            return ret;
        }

        return quotes[ids[0]].quote.policytype;
    } 

    logRequests(calcInputs: CalcInput[], policyType: number, requestType: string) {
        let requests = [];
        for (let calcInput of calcInputs) {
            requests.push(this.GetEstimation(calcInput, policyType, requestType));
        }

        forkJoin(requests).subscribe(results => {
            //calling quoter to log -- no need for data
        });
    }

    EstimateResponse(response: Object, policy: number): QuoterResponse {
        let ret;
        if (response[1]) {
            this.quoterResponse = this.calcInput.VerifiedByEngineer === true ? response[0] as QuoterResponse : response[1] as QuoterResponse;
            ret = this.calcInput.VerifiedByEngineer === true ? response[0] as QuoterResponse : response[1] as QuoterResponse;
        }
        else {
            this.quoterResponse = response[0] ? response[0] as QuoterResponse : response as QuoterResponse;
            ret = response[0] ? response[0] as QuoterResponse : response as QuoterResponse;
        }

        return ret;
        //return this.EstimateResponseDecimals(ret);
    }

    //note: this was added to produce decimal numbers to be filtered out of report.
    EstimateResponseDecimals(response: QuoterResponse): QuoterResponse {
        let fields = ["DwellingCoverage", "DwellingDeductible", "BuildingCodeUpgrade", "PersonalPropertyCoverage",
            "PersonalPropertyDeductible", "LossOfUseCoverage", "BuildingPropertyCoverage", "BuildingPropertyDeductible",
            "LossAssessmentCoverage", "LossAssessmentDeductible", "BuildingCodeUpgradeLimit"];

        fields.forEach((field) => {
            if (response[field]) {
                response[field] = response[field] + 0.71;
            }
        });

        return response;
    }

    BuildEstimateGeneral(cRequest: CalcInput, policy: number, requestType: string) {
        this.calcInput = new CalcInput();
        this.calcInput.PropertyAddress = this.quoterRequest.StreetAddress;
        this.calcInput.Address2 = this.quoterRequest.Address2;
        this.calcInput.City = this.quoterRequest.City;
        this.calcInput.ZipCode = this.quoterRequest.ZipCode;
        this.calcInput.State = "CA";

        this.calcInput.PolicyEffectiveDate = this.quoterRequest.ActualStartDate ? this.quoterRequest.ActualStartDate.format("MM/DD/YYYY") : null;

        this.calcInput.ReferringSite = "PremiumCalculator";
        this.calcInput.ResidentialInsuranceCompany = this.quoterRequest.ParticipatingInsurer;

        this.calcInput.PersonalPropertyCoverage = cRequest.PersonalPropertyCoverage !== 0 ? cRequest.PersonalPropertyCoverage : null;
        this.calcInput.PersonalPropertyDeductible = cRequest.PersonalPropertyDeductible !== 0 ? cRequest.PersonalPropertyDeductible : null;
        this.calcInput.LossOfUseCoverage = cRequest.LossOfUseCoverage;
        this.calcInput.BreakablesCoverage = cRequest.BreakablesCoverage;
        this.calcInput.IsDefault = cRequest.IsDefault;
        this.calcInput.TransactionTypeID = this.getTransactionType(this.quoterRequest);

        this.calcInput.Headers = new HeadersReq();
        this.calcInput.Headers.PolicyType = policy;
        this.calcInput.Headers.SessionId = this.getSession();
        this.calcInput.Headers.RequestKey = this.helper.GenerateGuid();
        this.calcInput.Headers.RequestType = requestType;
    }

    getSession() {
        let ret = this.quoteStorageService.GetSession();

        if (ret) {
            return ret;
        }

        ret = this.helper.GenerateGuid();
        this.quoteStorageService.SaveSession(ret);

        return ret;
    }

    getTransactionType(quoterRequest: QuoterRequest) {
        if (this.calcDateService.isRenewPeriod(quoterRequest.ActualStartDate)) {
            return quoterRequest.IsRenewal ? 2 : 1
        }

        return null;
    }

    BuildEstimateHomeowner(cRequest: CalcInput) {
        this.calcInput.BuildingPropertyDeductible = cRequest.BuildingPropertyDeductible;
        this.calcInput.BuildingCodeUpgradeLimitIncrease = cRequest.BuildingCodeUpgradeLimitIncrease;
        this.calcInput.MasonryVeneerCoverage = cRequest.MasonryVeneerCoverage;
        this.calcInput.Construction = this.quoterRequest.IsWoodFrame ? 0 : 1;
        this.calcInput.NumberOfStories = this.quoterRequest.numberOfStories;
        this.calcInput.FoundationType = this.quoterRequest.foundationTypeVariable;
        this.calcInput.InsuredValueOfHome = this.quoterRequest.InsuredValue;
        this.calcInput.RoofType = this.getRoofTypeValue(this.quoterRequest.roofTypeVariable);
        this.calcInput.YearBuilt = this.quoterRequest.YearBuilt;
        this.calcInput.VerifiedByEngineer = !this.quoterRequest.HrdRequirementsConfirmed ? false : this.quoterRequest.HrdRequirementsVerifiedByEngineer;
        this.calcInput.AreCrippleWallsBraced = this.quoterRequest.HrdRequirementsConfirmed.toString();
        this.calcInput.HasCrippleWalls = this.quoterRequest.HrdRequirementsConfirmed.toString();
        // tslint:disable-next-line:max-line-length
        this.calcInput.IsHomeSecuredToFoundation =  (this.quoterRequest.HrdRequirementsConfirmed && this.quoterRequest.HrdRequirementsVerifiedByEngineer);
        this.calcInput.IsWaterHeaterSecuredToFrame = this.calcInput.IsHomeSecuredToFoundation;
        this.calcInput.yesCompare = cRequest.yesCompare;
        this.calcInput.comparing = cRequest.comparing;
    }

    BuildEstimateCondo(cRequest: CalcInput) {
        this.calcInput.BuildingPropertyCoverage = cRequest.BuildingPropertyCoverage;
        this.calcInput.BuildingPropertyDeductible = cRequest.BuildingPropertyDeductible;
        this.calcInput.IsFairMarketValueGreaterThan135K = this.quoterRequest.IsFairMarketValueOver135k;
        this.calcInput.LossAssessmentCoverage = cRequest.LossAssessmentCoverage;
        this.calcInput.LossAssessmentDeductible = cRequest.LossAssessmentDeductible;
    }

    BuildEstimateMobile(cRequest: CalcInput) {
        this.calcInput.BuildingPropertyDeductible = cRequest.BuildingPropertyDeductible;
        this.calcInput.BuildingCodeUpgradeLimitIncrease = cRequest.BuildingCodeUpgradeLimitIncrease;

        this.calcInput.HasEarthquakeResistantBracingSystem = (this.quoterRequest.HasBracingSystem) ?
            this.quoterRequest.HasInspection : this.quoterRequest.HasBracingSystem;
        this.calcInput.YearBuilt = this.quoterRequest.YearBuilt;
        this.calcInput.InsuredValueOfHome = this.quoterRequest.InsuredValue;
        this.calcInput.yesCompare = cRequest.yesCompare;
        this.calcInput.comparing = cRequest.comparing;
    }

    BuildEstimatation(cRequest: CalcInput, policy: number, requestType: string) {
        this.BuildEstimateGeneral(cRequest, policy, requestType);

        if (policy === moduleType.homeowner) {
            this.BuildEstimateHomeowner(cRequest);   
        }

        if (policy === moduleType.condo) {
            this.BuildEstimateCondo(cRequest);
        }

        if (policy === moduleType.mobilehome) {
            this.BuildEstimateMobile(cRequest);
        }

        return this.calcInput;
    }

    BuildEndpoint(policy: number, policyDate: any) {
        let endpointConfig = null;
        switch (policy) {
            case moduleType.homeowner:
                const homeownerEndpoint = "homeownerEndpoint";
                endpointConfig = this.getEndpoint(homeownerEndpoint, policyDate);
                break;
            case moduleType.renter:
                endpointConfig = this.getEndpoint("renterEndpoint", policyDate);
                break;
            case moduleType.condo:
                endpointConfig = this.getEndpoint("condoEndpoint", policyDate);
                break;
            case moduleType.mobilehome:
                const mobileEndpoint = "mobileEndpoint";
                endpointConfig = this.getEndpoint(mobileEndpoint, policyDate);
                break;
        }

        return endpointConfig;
    }

    /* NOTE: These two functions are duplicates from calc-dates.services.ts
     * At some point need to refactor and break the circular dependencies between these two services
     */

    /*
    private isAfter2016(dt: Date | moment.Moment) {
        if (!dt) {
            return false;
        }

        dt = this.toDate(dt);
        if (dt.getTime() > new Date(QUOTER_2016_ENDDATE).getTime()) {
            return true;
        }

        return false;
    }
    */

    /*
    private isAfter2019(dt: Date | moment.Moment) {
        if (!dt) {
            return false;
        }

        dt = this.toDate(dt);
        if (dt.getTime() > new Date(QUOTER_2019_ENDDATE).getTime()) {
            return true;
        }

        return false;
    }
    */

    private toDate(dt: Date | moment.Moment): Date {
        let cdt = moment.isMoment(dt) ? dt.toDate() : dt;
        cdt.setHours(0, 0, 0, 0);

        return cdt;
    }

}
