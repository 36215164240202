import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CalcInput } from "../../models/CalcInput.model";
import { Estimate } from "../../models/estimate.model";
import { moduleType } from "../../models/module.type";
import { QuoterRequest } from "../../models/quoterRequest.model";
import { QuoterResponse } from "../../models/QuoterResponse.model";
import { CalcDatesService } from "../calc-dates/calc-dates.service";
import { HazardReductionService } from "../hazard-reduction/hazard-reduction.service";
import { TranslationService } from "../translation/translation.service";

export const ESTIMATE_NO_VALUE = "-";

@Injectable({
    providedIn: "root"
})

export class EstimateBuilderService {
    constructor(
        private translate: TranslateService,
        private translationService: TranslationService,
        private calcDateService: CalcDatesService,
        private hazardReductionService: HazardReductionService) {
    }

    private buildBase(calcInput: CalcInput, quoterRequest: QuoterRequest, quoterResponse: QuoterResponse): Estimate {
        let estimate = new Estimate();

        estimate.PropertyAddress = quoterRequest.StreetAddress;
        estimate.Address2 = quoterRequest.Address2;
        estimate.City = quoterRequest.City;
        estimate.ZipCode = quoterRequest.ZipCode;
        estimate.State = "CA";
        estimate.FullAddress = this.buildAddress(quoterRequest);

        estimate.PolicyDate = new Date(quoterRequest.StartDate.year, quoterRequest.StartDate.month-1, quoterRequest.StartDate.day, 0, 0, 0, 0);
        estimate.PolicyEffectiveDate = this.formatDate(estimate.PolicyDate);
        estimate.PolicyType = quoterRequest.policytype;
        estimate.Position = calcInput.position;
        estimate.YesCompare = calcInput.yesCompare;
        estimate.IsDefault = calcInput.IsDefault;
        estimate.IsFrfMode = this.calcDateService.isFrfMode(quoterRequest.ActualStartDate, quoterRequest.IsRenewal);

        estimate.ReferringSite = "PremiumCalculator";
        estimate.ResidentialInsuranceCompany = quoterRequest.ParticipatingInsurer;

        estimate.PersonalPropertyCoverage = quoterResponse.PersonalPropertyCoverage !== 0 ? quoterResponse.PersonalPropertyCoverage : null;
        estimate.PersonalPropertyDeductible = calcInput.PersonalPropertyDeductible !== 0 ? calcInput.PersonalPropertyDeductible : null;
        
        if (estimate.PersonalPropertyCoverage) {
            estimate.IsChoice = this.isChoice(estimate.PersonalPropertyDeductible, estimate.PersonalPropertyCoverage);
            estimate.PersonalPropertyCoverageAmount = this.formatMoney(estimate.PersonalPropertyCoverage, false);
            estimate.PersonalPropertyDeductibleAmount = this.formatPersonalPropertyDeductible(estimate.PersonalPropertyCoverage, estimate.PersonalPropertyDeductible, !estimate.IsChoice);
            estimate.PersonalPropertyDeductiblePercent = this.formatPersonalPropertyPercent(estimate.PersonalPropertyDeductible, !estimate.IsChoice);
        }
        else {
            estimate.IsChoice = false;
            estimate.PersonalPropertyCoverageAmount = this.formatMoney(0, false);
            estimate.PersonalPropertyDeductibleAmount = ESTIMATE_NO_VALUE;
            estimate.PersonalPropertyDeductiblePercent = ESTIMATE_NO_VALUE;
        }
  
        estimate.LossOfUseCoverage = quoterResponse.LossOfUseCoverage;
        estimate.LossOfUseCoverageAmount = this.formatMoney(estimate.LossOfUseCoverage, false);
        estimate.BreakablesCoverage = calcInput.BreakablesCoverage;
        estimate.BreakablesCoverageReadable = estimate.IsFrfMode ? this.translate.instant("COMPARE_SEND.FIELDS.NA") : this.formatYesNo(estimate.BreakablesCoverage);

        estimate.AnnualPremium = quoterResponse.AnnualPremium;
        estimate.AnnualPremiumAmount = this.formatMoney(estimate.AnnualPremium, true);
        estimate.MonthlyPremium = quoterResponse.MonthlyPremium;
        estimate.MonthlyPremiumAmount = this.formatMoney(estimate.MonthlyPremium, true);

        return estimate;
    }

    private buildRenter(calcInput: CalcInput, quoterRequest: QuoterRequest, quoterResponse: QuoterResponse): Estimate {
        let estimate = this.buildBase(calcInput, quoterRequest, quoterResponse);

        estimate.IsChoice = false;
        estimate.PolicyName = this.getPolicyName(estimate.PolicyType, estimate.IsChoice);

        estimate.LossAssessmentCoverage = 0;
        estimate.LossAssessmentCoverageAmount = "-";
        estimate.LossAssessmentDeductibleAmount = "$0";
        estimate.LossAssessmentDeductiblePercent = "-";

        return estimate;
    }

    private buildHomeowner(calcInput: CalcInput, quoterRequest: QuoterRequest, quoterResponse: QuoterResponse): Estimate {
        let estimate = this.buildBase(calcInput, quoterRequest, quoterResponse);

        estimate.BuildingPropertyDeductible = calcInput.BuildingPropertyDeductible;
        estimate.BuildingCodeUpgradeLimitIncrease = calcInput.BuildingCodeUpgradeLimitIncrease;
        estimate.BuildingCodeUpgradeLimitIncreaseAmount = this.formatMoney(estimate.BuildingCodeUpgradeLimitIncrease, false);
        estimate.MasonryVeneerCoverage = calcInput.MasonryVeneerCoverage;
        estimate.MasonryVeneerCoverageReadable = estimate.IsFrfMode ? this.translate.instant("COMPARE_SEND.FIELDS.NA") : this.formatYesNo(estimate.MasonryVeneerCoverage);
        estimate.IsChoice = this.isChoice(estimate.PersonalPropertyDeductible, estimate.PersonalPropertyCoverage);
        estimate.Construction = quoterRequest.IsWoodFrame ? 1 : 0;
        estimate.ConstructionReadable = this.formatYesNo(estimate.Construction);
        estimate.NumberOfStories = quoterRequest.numberOfStories;
        estimate.NumberOfStoriesReadable = this.formatNumberOfStories(estimate.NumberOfStories);
        estimate.FoundationType = quoterRequest.foundationTypeVariable;
        estimate.FoundationTypeName = this.buildFoundationName(quoterRequest.foundationTypeVariable);
        estimate.InsuredValueOfHome = quoterRequest.InsuredValue;
        estimate.InsuredValueOfHomeAmount = this.formatMoney(estimate.InsuredValueOfHome, false);
        estimate.DwellingDeductibleAmount = this.formatDeductible(estimate.InsuredValueOfHome, estimate.BuildingPropertyDeductible);
        estimate.DwellingDeductiblePercent = this.formatPercent(estimate.BuildingPropertyDeductible);
        estimate.RoofType = quoterRequest.roofTypeVariable.toString();
        estimate.RoofTypeName = this.buildRoofType(quoterRequest.roofTypeVariable, estimate.PolicyDate);
        estimate.YearBuilt = quoterRequest.YearBuilt;
        estimate.VerifiedByEngineer = !quoterRequest.HrdRequirementsConfirmed ? false : quoterRequest.HrdRequirementsVerifiedByEngineer;
        estimate.AreCrippleWallsBraced = quoterRequest.HrdRequirementsConfirmed.toString();
        estimate.HasCrippleWalls = quoterRequest.HrdRequirementsConfirmed.toString();
        // tslint:disable-next-line:max-line-length
        estimate.IsHomeSecuredToFoundation = (quoterRequest.HrdRequirementsConfirmed && quoterRequest.HrdRequirementsVerifiedByEngineer);
        estimate.IsWaterHeaterSecuredToFrame = calcInput.IsHomeSecuredToFoundation;
        estimate.YesCompare = calcInput.yesCompare;
        estimate.Comparing = calcInput.comparing;
        estimate.PolicyName = this.getPolicyName(estimate.PolicyType, estimate.IsChoice);

        //hrd
        const hrdResult = this.hazardReductionService.GetHrdQualificationStatus(quoterRequest);
        estimate.HrdQualified = hrdResult.qualified;
        estimate.HrdStatus = hrdResult.status;
        estimate.HrdStatusReadable = this.translate.instant(estimate.HrdStatus);
        estimate.HrdDiscount = hrdResult.discount;
        estimate.HrdDiscountReadable = hrdResult.discount > 0 ? this.formatPercent(hrdResult.discount) : "";
        
        return estimate;
    }

    private buildMobilehome(calcInput: CalcInput, quoterRequest: QuoterRequest, quoterResponse: QuoterResponse): Estimate {
        let estimate = this.buildBase(calcInput, quoterRequest, quoterResponse);

        estimate.BuildingPropertyDeductible = calcInput.BuildingPropertyDeductible;
        estimate.BuildingCodeUpgradeLimitIncrease = calcInput.BuildingCodeUpgradeLimitIncrease;
        estimate.BuildingCodeUpgradeLimitIncreaseAmount = this.formatMoney(estimate.BuildingCodeUpgradeLimitIncrease , false);

        estimate.HasEarthquakeResistantBracingSystem = (quoterRequest.HasBracingSystem) ?
            quoterRequest.HasInspection : quoterRequest.HasBracingSystem;
        estimate.HasEarthquakeResistantBracingSystemReadable = this.formatYesNo(estimate.HasEarthquakeResistantBracingSystem);
        estimate.YearBuilt = quoterRequest.YearBuilt;
        estimate.InsuredValueOfHome = quoterRequest.InsuredValue;
        estimate.InsuredValueOfHomeAmount = this.formatMoney(estimate.InsuredValueOfHome, false);
        estimate.DwellingDeductibleAmount = this.formatDeductible(estimate.InsuredValueOfHome, estimate.BuildingPropertyDeductible);
        estimate.DwellingDeductiblePercent = this.formatPercent(estimate.BuildingPropertyDeductible);
        estimate.IsChoice = this.isChoice(calcInput.PersonalPropertyDeductible, calcInput.PersonalPropertyCoverage);
        estimate.YesCompare = calcInput.yesCompare;
        estimate.Comparing = calcInput.comparing;
        estimate.PolicyName = this.getPolicyName(estimate.PolicyType, estimate.IsChoice);

        //hrd
        const hrdResult = this.hazardReductionService.GetHrdQualificationStatus(quoterRequest);
        estimate.HrdQualified = hrdResult.qualified;
        estimate.HrdQualifiedReadable = this.formatYesNo(estimate.HrdQualified);
        estimate.HrdStatus = hrdResult.status;
        estimate.HrdStatusReadable = this.translate.instant(estimate.HrdStatus);
        estimate.HrdDiscount = hrdResult.discount;
        estimate.HrdDiscountReadable = hrdResult.discount > 0 ? this.formatPercent(hrdResult.discount) : "";

        return estimate;
    }

    private buildCondo(calcInput: CalcInput, quoterRequest: QuoterRequest, quoterResponse: QuoterResponse): Estimate {
        let estimate = this.buildBase(calcInput, quoterRequest, quoterResponse);

        estimate.BuildingPropertyCoverage = calcInput.BuildingPropertyCoverage;
        estimate.BuildingPropertyDeductible = calcInput.BuildingPropertyDeductible;
        estimate.BuildingPropertyCoverageAmount = this.formatMoney(estimate.BuildingPropertyCoverage, false);
        estimate.BuildingPropertyDeductibleAmount = this.formatDeductible(estimate.BuildingPropertyCoverage, estimate.BuildingPropertyDeductible);
        estimate.BuildingPropertyDeductiblePercent = estimate.BuildingPropertyDeductible ? this.formatPercent(estimate.BuildingPropertyDeductible) : ESTIMATE_NO_VALUE;
        estimate.IsFairMarketValueGreaterThan135K = quoterRequest.IsFairMarketValueOver135k;
        estimate.DwellingDeductibleAmount = this.formatDeductible(estimate.InsuredValueOfHome,  estimate.BuildingPropertyDeductible);
        estimate.DwellingDeductiblePercent = this.formatPercent(estimate.BuildingPropertyDeductible);
        estimate.LossAssessmentCoverage = calcInput.LossAssessmentCoverage;
        estimate.LossAssessmentCoverageAmount = this.formatMoney(estimate.LossAssessmentCoverage , false);
        estimate.LossAssessmentDeductible = calcInput.LossAssessmentDeductible;
        estimate.LossAssessmentDeductibleAmount = estimate.LossAssessmentCoverage !== 0 ? this.formatMoney(((estimate.LossAssessmentCoverage * estimate.LossAssessmentDeductible) / 100), false) : '-';
        estimate.LossAssessmentDeductiblePercent = estimate.LossAssessmentDeductible !== 0 ? this.formatPercent(estimate.LossAssessmentDeductible) : '-';
        estimate.IsChoice = false;
        estimate.PolicyName = this.getPolicyName(estimate.PolicyType, estimate.IsChoice);

        return estimate;
    }

    BuildEstimate(calcInput: CalcInput, quoterRequest: QuoterRequest, quoterResponse: QuoterResponse): Estimate {
        const policyType = quoterRequest.policytype;

        if (policyType === moduleType.homeowner) {
            return this.buildHomeowner(calcInput, quoterRequest, quoterResponse);
        }

        if (policyType === moduleType.renter) {
            return this.buildRenter(calcInput, quoterRequest, quoterResponse);
        }

        if (policyType === moduleType.condo) {
            return this.buildCondo(calcInput, quoterRequest, quoterResponse);
        }

        if (policyType === moduleType.mobilehome) {
            return this.buildMobilehome(calcInput, quoterRequest, quoterResponse);
        }
    }

    private buildAddress(quoterRequest: QuoterRequest): string {
        let address = "";
        if (quoterRequest.StreetAddress !== null) {
            address += quoterRequest.StreetAddress + ", ";
        }
        if (quoterRequest.Address2 !== null) {
            address += quoterRequest.Address2 + ", ";
        }
        if (quoterRequest.City !== null) {
            address += quoterRequest.City + ", ";
        }
        if (quoterRequest.AddressState !== null) {
            address += quoterRequest.AddressState + " ";
        }
        if (quoterRequest.ZipCode !== null) {
            address += quoterRequest.ZipCode;
        }
        return address;
    }

    private getPolicyName(policy: number, isChoice: boolean): string {
        let policyType = "";
        if (policy === moduleType.renter) {
            policyType = "GLOBAL.Renters";
        }

        if (policy === moduleType.condo) {
            policyType = "GLOBAL.CONDO_OWNERS";
        }

        if (policy === moduleType.homeowner) {
            policyType = isChoice ? "GLOBAL.Homeowners Choice" : "GLOBAL.Homeowners";
        }
        if (policy === moduleType.mobilehome) {
            policyType = isChoice ? "GLOBAL.Mobilehome Choice" : "GLOBAL.Mobilehome"
        }

        return this.translate.instant(policyType);
    }

    private isChoice(personalPropertyDeductible: any, personalPropertyCoverage: any) {
        //backwards logic but not sure opposite is true given the values passed in tend to be different types
        return !(!personalPropertyDeductible && personalPropertyCoverage)
    }

    private buildFoundationName(foundationType: number): string {
        const foundationTypeVariableOptions = [
            this.translate.instant("HOMEOWNERS.QUESTIONS.FOUNDATIONS.SLAB"),
            this.translate.instant("HOMEOWNERS.QUESTIONS.FOUNDATIONS.RAISED"),
            this.translate.instant("HOMEOWNERS.QUESTIONS.FOUNDATIONS.OTHER")
        ];

        return foundationTypeVariableOptions[foundationType];
    }

    private buildRoofType(roofType: number, policyDate: any): string {
        const roofTypeVariableOptions = [
            this.translate.instant(this.translationService.whichTranslation("1/1/2025", policyDate, "HOMEOWNERS.QUESTIONS.ROOF_TYPES.TILE", "HOMEOWNERS.QUESTIONS.ROOF_TYPES.TILE_RFF2025")),
            this.translate.instant("HOMEOWNERS.QUESTIONS.ROOF_TYPES.COMPOSITION"),
            this.translate.instant("HOMEOWNERS.QUESTIONS.ROOF_TYPES.WOODSHAKE"),
            this.translate.instant("HOMEOWNERS.QUESTIONS.ROOF_TYPES.OTHER")
        ];

        return roofTypeVariableOptions[roofType];
    }

    public formatDate(dateObj: Date): string {
        //because of course the type on this field changes per process
        if (!(dateObj instanceof Date)) {
            dateObj = new Date(dateObj);
        }

        const dd = dateObj.getDate();
        let ddv = dd.toString();
        if (dd < 10) {
            ddv = "0" + dd.toString();
        }
        const mm = dateObj.getMonth() + 1; //January is 0!
        let mmv = mm.toString();
        if (mm < 10) {
            mmv = "0" + mm.toString();
        }
        const yyyy = dateObj.getFullYear();

        return mmv + '/' + ddv + '/' + yyyy;
    }

    private formatMoney(value, isDecimal) : string {
        if (isDecimal && value) {
            return "$" + (value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
        else {
            return "$" + Math.trunc(value).toLocaleString();
        }
    }

    private formatPersonalPropertyDeductible(coverage, deductible: any, isCompare: boolean) : string {
        if (!deductible) {
            if (isCompare) {
                return this.translate.instant("COMPARE_SEND.FIELDS.NA");
            }
            else {
                return "-";
            }
        }

        return this.formatDeductible(coverage, deductible);
    }

    private formatPersonalPropertyPercent(deductible, isCompare) : string {
        if (!deductible) {
            if (isCompare) {
                return this.translate.instant("COMPARE_SEND.FIELDS.NA");
            }
            else {
                return "-";
            }
        }

        return this.formatPercent(deductible);
    }

    private formatDeductible(coverage, deductible) : string {
        if (!deductible || deductible <= 0) {
            return "-";
        }

        return this.formatMoney(((coverage * deductible) / 100), false);
    }

    private formatPercent(value) : string {
        return value + "%";
    }

    private formatYesNo(value) : string {
        const val = value ? "GLOBAL.YES" : "GLOBAL.NO";
        return this.translate.instant(val);
    }

    private formatNumberOfStories(val): string {
        if (val === 0) {
            return this.translate.instant("HOMEOWNERS.QUESTIONS.STORIES.STORIES_SINGLE");
        }

        return this.translate.instant("HOMEOWNERS.QUESTIONS.STORIES.STORIES_GREATER_THAN_ONE");
    }
}
