<div class="cea-page" id="cea-page-1" style="padding-top: 0px;">
  <div class="cea-main-view">
    <form name="theForm" novalidate class="general-form">
      <div class="row cea-form">
        <div class="cea-col-sm-12 cea-col-md-12 offset-md-0 offset-sm-0">
            <div id="form-container">
                <div class="form-group required" name="dp">
                    <!-- Date Picker Control (languages: en, es, zh-cn) -->
                    <div class="input-group">
                        <table border="0" style="display:block">
                            <tr class="cea-visible-xxs-block">
                                <td colspan="3" style="text-align: left;"><label for="startdate" trackVisibility (visibile)="mobileVisible($event, 0)">{{'HOMEOWNERS.START_DATE_PLACEHOLDER' | translate}}</label></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="input-group-btn">
                                        <button class="btn cea-help-btn left" type="button"
                                                (click)="calanderButtonClicked(d)"
                                                attr.aria-label="{{'HOMEOWNERS.START_DATE_PLACEHOLDER' | translate}}">
                                            <span class="fas fa-calendar-alt"></span>
                                        </button>
                                    </span>
                                </td>
                                <td style="width:100%">
                                    <input id="startdate" #startDate
                                           autocomplete="none"
                                           name="startdate"
                                           [title]="'HOMEOWNERS.POLICY_START' | translate"
                                           type="text"
                                           class="form-control"
                                           [placeholder]="'HOMEOWNERS.START_DATE_PLACEHOLDER'| ceaPlaceholder: !isMobileView | translate"
                                           [minDate]="getMinDateVal()"
                                           [maxDate]="getMaxDateVal()"
                                           firstDayOfWeek="7"
                                           attr.aria-label="{{'HOMEOWNERS.START_DATE_PLACEHOLDER' | translate}}"
                                           [(ngModel)]="quoterRequest.StartDate" ngbDatepicker #d="ngbDatepicker"
                                           (click)="calanderFieldClicked(d)"
                                           (ngModelChange)="validateSetStartDate(quoterRequest.StartDate)" />
                                </td>
                                <td>
                                    <span class="input-group-btn">
                                        <app-cea-help-button [stype]="'input'"
                                                             [title]="'HOMEOWNERS.HELP.POLICY_START.TITLE'"
                                                             [text1]="'HOMEOWNERS.HELP.POLICY_START.TEXT'">
                                        </app-cea-help-button></span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="cea-hidden-xss ng-scope ng-isolate-scope alert alert-danger"
                     *ngIf="quoterRequestError.startDateOutOfRange"
                     [innerHTML]="dateRangeErrorMessage"></div>
                <div class="cea-hidden-xss ng-scope ng-isolate-scope alert alert-danger"
                     *ngIf="quoterRequestError.startDateNotSet && startDateTouched"
                     [innerHTML]="'ERRORS.SELECT_POLICY_YEAR_FIRST' | translate"></div>


                <div *ngIf="isRenewPeriod()" class="form-group required" style="margin-bottom: 3em;">
                    <div class="cea-radio-row" style="margin-left: unset">
                        <div class="cea-radio-labels">
                            <label>{{'HOMEOWNERS.FRF_QUOTE_TYPE.QUESTION' | translate }}</label>
                        </div>
                        <div class="cea-radio-buttons" style="width: unset; float: right; z-index: 10;">
                            <div class="radio-group-accessories">
                                <app-cea-help-button ng-if="helpSlug"
                                                     [stype]="'radio'"
                                                     [title]="'HOMEOWNERS.QUESTIONS.HELP.TYPE_QUOTE.TITLE'"
                                                     [text1]="'HOMEOWNERS.QUESTIONS.HELP.TYPE_QUOTE.TEXT'">
                                </app-cea-help-button>

                                <label class="radio-inline button-caption">
                                    <input name="radioGroupIsRenewal" id="radioNew_IsRenewal"
                                           [(ngModel)]="quoterRequest.IsRenewal" [value]="false" type="radio" (change)="validateRenewal()">{{'HOMEOWNERS.FRF_QUOTE_TYPE.NEW' | translate }}
                                </label>
                                <label class="radio-inline button-caption no-caption">
                                    <input name="radioGroupIsRenewal" id="radioRenewal_IsRenewal"
                                           [(ngModel)]="quoterRequest.IsRenewal" [value]="true" type="radio" (change)="validateRenewal()">{{'HOMEOWNERS.FRF_QUOTE_TYPE.RENEWAL' | translate }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cea-hidden-xss alert alert-danger" style="margin-top: 0.2rem !important; clear: both;"
                     *ngIf="quoterRequestError.renewalNeeded && isRenewPeriod()"
                     [innerHTML]="'ERRORS.COMPLETE_REQUIRED_FIELDS' | translate"></div>




                <div class="form-group required" style="clear: both;"
                     [ngClass]="quoterRequestError.ParticipatingInsurerIsEmpty === true ? 'has-error': ''">
                    <label for="participatingInsurer" class="cea-visible-xxs-block">{{'HOMEOWNERS.INSURANCE_COMPANY_PLACEHOLDER' | translate }}</label>
                    <div class="input-group">
                        <div class="drop-down-wrapper">
                            <div class="drop-down-arrow">
                                <select id="participatingInsurer" name="participatingInsurer"
                                        class="form-control" [(ngModel)]="quoterRequest.ParticipatingInsurer"
                                        (click)="participatingInsurerOpened()" (change)="validateInsurer()"
                                        attr.aria-label="{{'HOMEOWNERS.INSURANCE_COMPANY_PLACEHOLDER' | translate}}"
                                        style="padding-left: 8px">
                                    <option value="" disabled>
                                        {{'HOMEOWNERS.INSURANCE_COMPANY_PLACEHOLDER' | ceaPlaceholder: !isMobileView | translate }}
                                    </option>
                                    <option *ngIf="!quoterRequestError.participatingInsurerDateNeeded && defaultSetting" [ngValue]="defaultSetting">
                                        {{defaultSetting}}
                                    </option>
                                    <ng-container>
                                        <option *ngFor="let item of validPiNames" [ngValue]="item | translate"
                                                title="{{item.replace('-2016', '') | translate}}">
                                            {{ item.replace('-2016', '') | translate}}
                                        </option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>
                        <span class="input-group-btn">

                            <app-cea-help-button [stype]="'input'"
                                                 [title]="'HOMEOWNERS.HELP.CHOOSE_INSURANCE_HELP.TITLE'"
                                                 [text1]="'HOMEOWNERS.HELP.CHOOSE_INSURANCE_HELP.TEXT'">
                            </app-cea-help-button>

                        </span>
                    </div>
                </div>

                <div class="cea-hidden-xss ng-scope ng-isolate-scope alert alert-danger"
                     *ngIf="quoterRequestError.ParticipatingInsurerIsEmpty"
                     [innerHTML]="'ERRORS.COMPLETE_REQUIRED_FIELDS' | translate"></div>

                <div class="form-group required"
                     [ngClass]="quoterRequestError.street || quoterRequestError.streetIsEmpty? 'has-error': ''"
                     app-autocomplete (setAddress)="getAddress($event)" adressType="geocode"
                     [setSavedAddress]="quoterRequest.StreetAddress" (setAddressError)="setAddressError($event)"
                     [isMobileView]="isMobileView"
                     (setAddressPickedFromBrowser)="setAddressPickedFromBrowser($event)">
                </div>

                <div class="cea-hidden-xss alert alert-danger" style="margin-top: 0.2rem !important;"
                     *ngIf="!quoterRequest.StreetAddress && quoterRequestError.street"
                     [innerHTML]="'ERRORS.FILL_OUT_FIELD' | translate"></div>
                <div class="cea-hidden-xss alert alert-danger" style="margin-top: 0.2rem !important;"
                     *ngIf="quoterRequestError.streetIsEmpty"
                     [innerHTML]="'ERRORS.COMPLETE_REQUIRED_FIELDS' | translate"></div>

                <div class="form-group" [ngClass]="{'margin-1': !(quoterRequestError.street || quoterRequestError.streetIsEmpty)}">
                    <label for="address2" class="cea-visible-xxs-block" trackVisibility (visibile)="mobileVisible($event, 1)">{{'HOMEOWNERS.ADDRESS2_PLACEHOLDER' | translate }}</label>
                    <input id="address2" name="address2" type="text"
                           placeholder="{{'HOMEOWNERS.ADDRESS2_PLACEHOLDER' | ceaPlaceholder: !isMobileView | translate }}"
                           class="form-control"
                           attr.aria-label="{{'HOMEOWNERS.ADDRESS2_PLACEHOLDER' | translate}}"
                           [(ngModel)]="quoterRequest.Address2">
                </div>

                <div class="form-group" cea-form-group-for="theForm.city">
                    <label for="city" class="cea-visible-xxs-block">{{'HOMEOWNERS.CITY_PLACEHOLDER' | translate }}</label>
                    <input id="city" name="city" type="text"
                           placeholder="{{'HOMEOWNERS.CITY_PLACEHOLDER' | ceaPlaceholder: !isMobileView | translate }}"
                           class="form-control"
                           attr.aria-label="{{'HOMEOWNERS.CITY_PLACEHOLDER' | translate}}"
                           [(ngModel)]="quoterRequest.City">
                </div>

                <div class="form-group" cea-form-group-for="theForm.state">
                    <label for="state" class="cea-visible-xxs-block">{{'HOMEOWNERS.STATE_PLACEHOLDER' | translate }}</label>
                    <input id="state" name="state" type="text"
                           placeholder="{{'HOMEOWNERS.STATE_PLACEHOLDER' | ceaPlaceholder: !isMobileView | translate }}"
                           class="form-control"
                           attr.aria-label="{{'HOMEOWNERS.STATE_PLACEHOLDER' | translate}}"
                           [(ngModel)]="quoterRequest.AddressState" (change)="checkModelValue()" (ngModelChange)="checkModelValue()">
                </div>
                <div class="cea-hidden-xss ng-scope ng-isolate-scope alert alert-danger"
                     *ngIf="quoterRequestError.AddressStateIsIncorrect"
                     [innerHTML]="'ERRORS.VALID_ADDRESS_STATE' | translate"></div>

                <!--zipcode-->
                <div class="form-group required"
                     [ngClass]="quoterRequestError.enterValidZipCode === true || quoterRequestError.zipCodeIsEmptyOrlengthIncorrect? 'has-error': ''">
                    <label for="zipcode" class="cea-visible-xxs-block" trackVisibility (visibile)="mobileVisible($event, 2)">{{'HOMEOWNERS.ZIPCODE_PLACEHOLDER' | translate }}</label>
                    <input id="zipcode" name="zipcode" type="text" (ngModelChange)="validateZipCode($event,false)"
                           placeholder="{{'HOMEOWNERS.ZIPCODE_PLACEHOLDER'| ceaPlaceholder: !isMobileView | translate }}" 
                           class="form-control"
                           attr.aria-label="{{'HOMEOWNERS.ZIPCODE_PLACEHOLDER' | translate}}"
                           [(ngModel)]="quoterRequest.ZipCode" numbersOnly />

                </div>
                <div class="cea-hidden-xss ng-scope ng-isolate-scope alert alert-danger"
                     *ngIf="quoterRequestError.enterValidZipCode"
                     [innerHTML]="zipCodeErrorMessage | translate"></div>

                <div class="cea-hidden-xss ng-scope ng-isolate-scope alert alert-danger"
                     *ngIf="quoterRequestError.zipCodeIsEmptyOrlengthIncorrect"
                     [innerHTML]="'ERRORS.COMPLETE_REQUIRED_FIELDS' | translate"></div>
                <div class="cea-hidden-xss ng-scope ng-isolate-scope alert alert-danger"
                     *ngIf="quoterRequestError.zipCodeIsEmptyShowLink" [innerHtml]="('ERRORS.INVALID_ZIPCODE' | translate).replace('[link]',zipCodeErrorMessage)">                </div>

                <div class="row cea-top-margin20 cea-bottom-margin20 cea-radio-row-adjustment radio-group-collapse form-group"
                     style="margin-left: 0px;">
                    <div class="cea-radio-row" style="margin-left:0px;margin-right:0px" *ngIf="policytype === page.condo">
                        <div class="cea-radio-labels">
                            <label>{{'CONDO.FAIR_MARKET' | translate }}</label>
                        </div>
                        <div class="cea-radio-buttons">
                            <div class="radio-group-accessories">
                                <app-cea-help-button ng-if="helpSlug"
                                                     [stype]="'radio'"
                                                     [title]="'CONDO.HELP.FAIR_MARKET.TITLE'"
                                                     [text1]="'CONDO.HELP.FAIR_MARKET.TEXT'">
                                </app-cea-help-button>

                                <label class="radio-inline button-caption">
                                    <input [name]="'radioGroup' + groupkey" id="radioTrue_IsFairMarketValueOver135k" ngModel="quoterRequest.IsFairMarketValueOver135k"
                                           [(ngModel)]="quoterRequest.IsFairMarketValueOver135k" [value]="true" type="radio">{{'GLOBAL.YES' | translate }}
                                </label>
                                <label class="radio-inline button-caption no-caption">
                                    <input [name]="'radioGroup' + groupkey" id="radioFalse_IsFairMarketValueOver135k" [(ngModel)]="quoterRequest.IsFairMarketValueOver135k" [value]="false" type="radio">{{'GLOBAL.NO' | translate }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>


                <!--Submit Buttons-->
                <div class="cea-radio-row-adjustment radio-group-collapse form-group submitBtn">
                    <div class="width-50">
                        <button class="btn btn-primary btn-block" (click)="goBack()" id="general-back-btn">
                            <em class="fas fa-arrow-left"></em>
                            {{'GLOBAL.BACK_BUTTON' | translate }}
                        </button>
                    </div>
                    <div class="width-50">
                        <button class="btn btn-block btn-primary" id="get-startedmh-btn" (click)="verifyAndSubmit()"
                                *ngIf="policytype === page.mobilehome || policytype === page.homeowner">
                            {{'HOMEOWNERS.GET_STARTED_BUTTON' | translate }}
                        </button>
                        <button class="btn btn-block btn-primary" id="get-startedrc-btn"
                                *ngIf="policytype === page.condo || policytype === page.renter" (click)="getEstimate()">
                            {{'HOMEOWNERS.QUESTIONS.GET_ESTIMATE_BUTTON' | translate }}
                        </button>
                    </div>
                </div>
                <!--Submit Buttons-->
            </div>
          <!--form-container-->
        </div>
      </div>
    </form>
    <div  style="display: none">
      <div class="cea-visible-lg">
        LG
      </div>
      <div class="cea-visible-md">
        MD
      </div>
      <div class="cea-visible-sm">
        SM
      </div>
      <div class="cea-visible-xs">
        XS
      </div>
      <div class="cea-visible-xss cea-visible-xxs">
        XXS
      </div>
      {{screenWidth}}
    </div>
  </div>
</div>
